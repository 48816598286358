import moment from 'moment';
import AdminsIcon from '../../../Assets/Icons/AdminsIcon';
import AvailableJobsIcon from '../../../Assets/Icons/AvailableJobsIcon';
import DoctorsIcon from '../../../Assets/Icons/DoctorsIcon';
import FacilityIcon from '../../../Assets/Icons/FacilityIcon';
import ReviewersIcon from '../../../Assets/Icons/ReviewersIcon';
// import TranscriptionistsIcon from "../../../Assets/Icons/TranscriptionistsIcon";
import { DATE_TIME_FORMAT } from '../../../Constants/StringConstants';

export const getSuperAdminDashboardConfig = dashboardData => {
  return [
    {
      heading: 'Admin',
      content: dashboardData?.adminCount,
      footerHeading: 'Last logged in Admin',
      footerContent: dashboardData.lastLogedinAdmin?.name
        ? `${dashboardData.lastLogedinAdmin?.name}  |  ${moment(
            dashboardData?.lastLogedinAdmin?.lastLoginTimeStamp
          ).format(DATE_TIME_FORMAT)}`
        : '--',
      color: '#78828F',
      icon: AdminsIcon,
      path: '#'
    },
    {
      heading: 'Doctors',
      content: dashboardData?.doctorCount,
      footerHeading: 'Last logged in Doctor',
      footerContent: dashboardData.lastLogedinDoctor?.name
        ? `${dashboardData.lastLogedinDoctor?.name}  |  ${moment(
            dashboardData?.lastLogedinDoctor?.lastLoginTimeStamp
          ).format(DATE_TIME_FORMAT)}`
        : '--',
      color: '#47A4EA',
      icon: DoctorsIcon,
      path: '#'
    },
    // {
    //     heading: 'Transcriptionists',
    //     content: dashboardData?.transcriptionistCount,
    //     footerHeading: 'Last logged in Transcriptionists',
    //     footerContent: dashboardData?.lastLogedinTranscriptionist?.name ? `${dashboardData?.lastLogedinTranscriptionist?.name}  |  ${moment(dashboardData?.lastLogedinTranscriptionist?.lastLoginTimeStamp).format(DATE_TIME_FORMAT)}` : '--',
    //     color: '#E277C2',
    //     icon: TranscriptionistsIcon,
    //     path: '#'
    // },
    {
      heading: 'Reviewers',
      content: dashboardData?.reviewerCount,
      footerHeading: 'Last logged in Reviewer',
      footerContent: dashboardData?.lastLogedinReviewer?.name
        ? `${dashboardData?.lastLogedinReviewer?.name}  |  ${moment(
            dashboardData?.lastLogedinReviewer?.lastLoginTimeStamp
          ).format(DATE_TIME_FORMAT)}`
        : '--',
      color: '#FF8901',
      icon: ReviewersIcon,
      path: '#'
    },
    {
      heading: 'Open Jobs',
      content: dashboardData?.openJobCount,
      footerHeading: 'Last job created on',
      footerContent: dashboardData?.lastJobCreatedOn
        ? `${moment(dashboardData?.lastJobCreatedOn).format(DATE_TIME_FORMAT)}`
        : '--',
      color: '#FB6464',
      icon: AvailableJobsIcon,
      path: '#'
    },
    {
      heading: 'Completed Jobs',
      content: dashboardData?.completedJobsCount,
      footerHeading: 'Last job completed on',
      footerContent: dashboardData?.lastJobCompletedOn
        ? `${moment(dashboardData?.lastJobCompletedOn).format(
            DATE_TIME_FORMAT
          )}`
        : '--',
      color: '#49C006',
      icon: AvailableJobsIcon,
      path: '#'
    },
    {
      heading: 'Total Facility',
      content: dashboardData?.facilityCount,
      footerHeading: 'Facility last updated on',
      footerContent: dashboardData?.facilityLastUpdatedOn
        ? `${moment(dashboardData.facilityLastUpdatedOn).format(
            DATE_TIME_FORMAT
          )}`
        : '--',
      color: '#01DDFE',
      icon: FacilityIcon,
      path: '#'
    }
  ];
};
