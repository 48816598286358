import React, { useEffect, useRef, useState } from 'react';
import { ContainerRow, TitleRow, DividerStyle } from '../styledComponents';
import {
  HeadingRow,
  ContentRow,
  ActionButton,
  StyledTextArea,
  CustomMoreButtonStyle,
  StyledMenuButton,
  CustomHeadingCol,
  DictationTemplateContainer,
  DictationTemplateHeader,
  DictationTemplateContent
} from './styledComponents';
import { useSelector } from 'react-redux';
import { Col, Dropdown, Menu, notification, Row } from 'antd';
import { useParams } from 'react-router-dom';
import {
  downloadAudio,
  getDemographics,
  sectionFetch,
  getHeadings,
  deleteSection,
  getInference,
  updateAudioTime,
  updateInference,
  updateInferenceEditFlag,
  forceUpdateJobInference,
  releaseJob
} from '../../../Apis/Jobs';
import {
  completeTranscriptorJob,
  rejectTranscriptorJob
} from '../../../Apis/Transcriptor';

import {
  completeReviewerJob,
  rejectReviewerJob,
  releaseReviewerJob
} from '../../../Apis/Reviewer';
import PageTitle from '../../../Utils/PageTitle';
import CustomTypography from '../../CustomComponents/CustomTypography';
import BreadCrumbComponent from '../../CustomComponents/BreadCrumbComponent';
import CustomAudioPlayer from '../../CustomComponents/AudioPlayer';
import Demographics from './Demographics';
import Spinner from '../../CustomComponents/Spinner';
import ConfirmationModal from '../../CustomComponents/ConfirmationModal';
import SuccessModal from '../../CustomComponents/SuccessModal';
import { useHistory, useLocation } from 'react-router-dom';
import ConfirmationIcon from '../../../Assets/Icons/ConfirmationIcon';
import RejectIcon from '../../../Assets/Icons/RejectIcon';
import DeleteIcon from '../../../Assets/Icons/DeleteIcon';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../../Constants/StringConstants';
import NewEditorComponent from '../../CustomComponents/NewEditorComponent';
import ReleaseJobModal from '../../CustomComponents/ReleaseJobModal';
import MoreHorizIcon from '../../../Assets/Icons/MoreHorizIcon';
import { completeDoctorJob } from '../../../Apis/Doctor';

const NewEditor = props => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const { jobId } = useParams();
  const audioRef = useRef();
  const [audioUrl, setAudioUrl] = useState(null);
  const [demographicsData, setDemographicsData] = useState([]);
  const [localDemographicsData, setLocalDemographicsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState(null);
  const [headings, setHeadings] = useState(null);
  const [dectationTemplates, setDectationTemplates] = useState([]);
  const [inferenceActive, setInferenceActive] = useState(false);
  const [currentTime] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [completeTranscription, setCompleteTranscription] = useState(false);
  const [rejectTranscription, setRejectTranscription] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [demographicsLoading, setDemographicsLoading] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [activeSectionContent, setActiveSectionContent] = useState('');
  const [inferenceData, setInferenceData] = useState([]);
  const [inferenceAnnotation, setInferenceAnnotation] = useState([]);
  const [saveStatus, setSaveStatus] = useState(null);
  const [changeStatus, setChangeStatus] = useState(false);
  const [comments, setComments] = useState('');
  const [jobName, setJobName] = useState('');
  const [editorLoading, setEditorLoading] = useState(true);
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [emptySection, setEmptySection] = useState(false);
  const [updateInferenceLoading, setUpdateInferenceLoading] = useState(false);
  const [editorDataPreviewValue] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [userType, setUserType] = useState('');
  const [userType1, setUserType1] = useState('doctor');
  const [viewOnly, setViewOnly] = useState(false);
  const [user] = useSelector(state => [state.userReducer]);
  const [localSectionIdMap] = useState(new Map());
  const [newInferenceActive, setNewInferenceActive] = useState(false);
  const [currentInferenceEngine, setCurrentInferenceEngine] = useState('');
  const [releaseJobId, setReleaseJobId] = useState(null);
  const [releaseJobModalVisible, setReleaseJobModalVisible] = useState(false);
  const [jobStage, setJobStage] = useState('');
  const [refetchDemographics, setRefetchDemographics] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const getUserRole = () => {
    const token = localStorage.getItem('accessToken');
    if (!token) return null;

    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload.scopes || null;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };
  useEffect(() => {
    const role = getUserRole();
    setUserRoles(role);
  }, []);
  useEffect(() => {
    if (changeStatus === true || saveStatus === 'Saving') {
      window.addEventListener('beforeunload', keepOnPage);
      return () => window.removeEventListener('beforeunload', keepOnPage);
    }
    // eslint-disable-next-line
  }, [saveStatus, changeStatus]);
  useEffect(() => {
    getDemographicsData();
  }, [localDemographicsData]);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setUserType(searchParams.get('userRole'));
    setJobStage(searchParams.get('jobStage'));

    setViewOnly(
      searchParams.get('jobStage') &&
        (searchParams.get('jobStage') === 'ready' ||
        searchParams.get('jobStage') === 'in-progress'
          ? false
          : true)
    );
    // setViewOnly(userType === 'doctor' ? false : true);

    setDemographicsLoading(true);
    getDemographics(jobId)
      .then(response => {
        if (response.data.success === true) {
          setLocalDemographicsData(response.data.result);
          setDemographicsLoading(false);
        } else {
          notification['error']({
            message: 'Demographics Fetch Failed',
            description: response.data.message
          });
          setDemographicsLoading(false);
        }
        setRefetchDemographics(false);
      })
      .catch(err => {
        console.log(err);
        setDemographicsLoading(false);
      });

    // eslint-disable-next-line
  }, [refetchDemographics]);

  useEffect(() => {
    fetchContents(activeSection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSection]);
  useEffect(() => {
    fetchContents(activeSection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const keepOnPage = event => {
    var message =
      "Warning!\n\nNavigating away from this page will delete your text if you haven't already saved it.";
    event.returnValue = message;
    return message;
  };

  const getAudio = () => {
    setLoading(true);
    downloadAudio(jobId)
      .then(response => {
        if (response.data.success === true) {
          getEditorData();
          setAudioUrl(response.data.result[0]);
          setLoading(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
          setLoading(false);
        }
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const getDemographicsData = () => {
    setDemographicsData(localDemographicsData);
  };

  const getInferenceData = async sectionId => {
    // Force update inference data on inference engine change
    if (currentInferenceEngine !== (newInferenceActive ? 'new' : 'old')) {
      await forceUpdateInferencedata();
    }
    setCurrentInferenceEngine(newInferenceActive ? 'new' : 'old');
    setDemographicsLoading(true);
    getInference(jobId, sectionId, newInferenceActive)
      .then(response => {
        if (response.data.success === true) {
          setInferenceData(response.data.result);
          setDemographicsLoading(false);
        } else {
          notification['error']({
            message: 'Inference Fetch Failed',
            description: response.data.message
          });
          setDemographicsLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setDemographicsLoading(false);
      });
  };

  const forceUpdateInferencedata = async () => {
    await forceUpdateJobInference(jobId);
  };

  const updateInferenceData = payload => {
    updateInference(jobId, activeSection, payload).then(response => {
      if (response.data.success) {
        fetchContents(activeSection);
      } else {
        notification['error']({
          message: 'Inference Update Failed',
          description: response.data.message
        });
      }
      setUpdateInferenceLoading(false);
    });
  };

  const updateInferenceFlag = (payload, isFromTable) => {
    updateInferenceEditFlag(jobId, activeSection, payload)
      .then(response => {
        if (response.data.success) {
          !isFromTable && fetchContents(activeSection);
          setUpdateInferenceLoading(false);
        } else {
          notification['error']({
            message: 'Edit Flag Update Failed',
            description: response.data.message
          });
          setUpdateInferenceLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setUpdateInferenceLoading(false);
      });
  };

  const fetchContents = (activeSection, fromSave) => {
    if (inferenceActive || fromSave) {
      if (activeSection && !activeSection.includes('new')) {
        if (activeSectionContent?.value?.trim().length === 0) {
          setEmptySection(true);
          getDemographicsData();
        } else {
          getInferenceData(activeSection);
          setEmptySection(false);
        }
      }
    } else if (!inferenceActive && !fromSave) {
      getDemographicsData();
    }
  };

  const formatTime = time => {
    const savedTime = moment(time);
    const now = moment(new Date());
    const diff = now.diff(savedTime, 'hours');

    if (diff > 12) {
      return savedTime.format(DATE_TIME_FORMAT);
    } else {
      return 'Today ' + savedTime.format('HH:mm:ss');
    }
  };
  function processValue(value) {
    return value
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '')
      .replace(/<\/?(ol|ul|li)>/g, '')
      .replace(/\n\s*\d+\.\s*/g, '\n'); // Remove numbered list formatting;`
    // .replace(/<(?!\/?(b|i|s|u)\b)[^>]*>/g, '');
  }
  const getEditorData = () => {
    setEditorLoading(true);
    sectionFetch(jobId)
      .then(response => {
        if (response.data.success) {
          for (let i = 0; i < response.data.result.length; i++) {
            const resultItem = response.data.result[i];
            for (let j = 0; j < resultItem.sections.length; j++) {
              const section = resultItem.sections[j];
              for (let k = 0; k < section.content.length; k++) {
                const contentItem = section.content[k];
                if (contentItem.type === 'Text') {
                  response.data.result[i].sections[j].content[k].value =
                    processValue(contentItem.value);
                }
              }
            }
          }

          setSections(
            response.data.result[0] && response.data.result[0].sections
          );

          setDectationTemplates(response.data.templates);
          // setEditorDataPreviewValue(
          //   response.data.result[0] && response.data.result[0].sections
          // );
          // setCurrentTime(response.data.result[0].audioTime);
          setActiveSection(
            response.data.result[0].sections &&
              response.data.result[0].sections.length > 0 &&
              response.data.result[0].sections[0].id
          );
          setJobName(response.data.jobName);
          setLastUpdateTime(response.data.result[0].lastUpdatedTime);
          setEditorLoading(false);
        } else {
          notification['error']({
            message: 'Section Fetch Failed',
            description: response.data.message
          });
          setEditorLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setEditorLoading(false);
      });
    getHeadings(jobId)
      .then(response => {
        if (response.data.success === true) {
          setHeadings(response.data.result);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (jobId) {
      getAudio();
      getDemographicsData();
    }
    // eslint-disable-next-line
  }, [jobId]);

  useEffect(() => {
    if (jobId) {
      fetchContents(activeSection);
    }
    // eslint-disable-next-line
  }, [selectedSection, inferenceActive, newInferenceActive]);

  useEffect(() => {
    if (currentTime) {
      if (audioRef.current && audioRef.current.audio.current) {
        audioRef.current.audio.current.currentTime = currentTime;
      }
    }
  }, [currentTime, audioRef]);

  const deleteSectionHandler = sectionId => {
    setSelectedSection(sectionId);
    setConfirmationModal(true);
  };

  const deleteConfirmationHandler = () => {
    let sectionIdToDelete = selectedSection;
    setConfirmLoading(true);
    if (selectedSection.includes('new')) {
      if (!localSectionIdMap.get(selectedSection)) {
        notification['error']({
          message: 'Failed',
          description: `Unable to process please try again after some time.`
        });
        setConfirmLoading(false);
        return;
      }
      sectionIdToDelete = localSectionIdMap.get(selectedSection);
      // setConfirmationModal(false);
    }
    deleteSection(jobId, sectionIdToDelete)
      .then(response => {
        setConfirmLoading(false);
        if (response.data.success === true) {
          setActiveSection(null);
          setInferenceActive(false);
          getEditorData();
          setConfirmationModal(false);
          setSuccessModal(true);
          setSelectedSection(null);
        } else {
          setConfirmLoading(false);
          getEditorData();
        }
      })
      .catch(err => {
        console.log(err);
        notification['error']({
          message: 'Failed',
          description: err
        });
        setConfirmLoading(false);
      });
  };

  const deleteCancelHandler = () => {
    setConfirmationModal(false);
    setCompleteTranscription(false);
    setRejectTranscription(false);
    setSelectedSection(null);
  };

  let BreadCrumbItems = [
    {
      name: 'Dashboard',
      path: '/dashboard'
    },
    {
      name: 'Jobs-list',
      path: `/dashboard/jobs-list/${jobStage}`
    },
    {
      name: userType === 'doctor' ? 'my-jobs' : 'jobs-to-review',
      path: `/dashboard/jobs-list/${
        userType === 'doctor' ? 'my-jobs' : 'jobs-to-review'
      }/${jobStage}?fromEditor=true`
    },
    {
      name: userType === 'doctor' ? 'Job Editor' : 'Job Review'
    }
  ];

  const confirmCompleteOrApprove = () => {
    setCompleteTranscription(true);
  };

  const confirmRejectorAssignBack = () => {
    setRejectTranscription(true);
  };

  const audioUpdate = audioTime => {
    if (audioTime !== 0) {
      updateAudioTime(jobId, audioTime)
        .then(response => {
          if (!response.data.success) {
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const completeOrApprove = () => {
    setConfirmLoading(true);
    userType === 'doctor' &&
      completeDoctorJob(jobId, user.sessionId)
        .then(response => {
          if (response.data.success) {
            setConfirmLoading(false);
            history.push(
              `/dashboard/jobs-list/${
                userType === 'doctor' ? 'my-jobs' : 'jobs-to-review'
              }/${jobStage}?fromEditor=true`
            );
            notification['success']({
              message: 'Success',
              description: response.data.message
            });
          } else {
            setConfirmLoading(false);
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
          }
        })
        .catch(err => {
          console.log(err);
          setConfirmLoading(false);
        });

    userType === 'reviewer' &&
      completeReviewerJob(jobId, user.sessionId)
        .then(response => {
          if (response.data.success) {
            setConfirmLoading(false);
            history.push(
              `/dashboard/jobs-list/${
                userType === 'doctor' ? 'my-jobs' : 'jobs-to-review'
              }/${jobStage}?fromEditor=true`
            );
            notification['success']({
              message: 'Success',
              description: response.data.message
            });
          } else {
            setConfirmLoading(false);
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
          }
        })
        .catch(err => {
          console.log(err);
          setConfirmLoading(false);
        });
  };

  const reject0rAssignBack = () => {
    setConfirmLoading(true);
    let rejectComment = {
      comment: comments
    };

    rejectReviewerJob(jobId, rejectComment, user.sessionId)
      .then(response => {
        if (response.data.success) {
          setConfirmLoading(false);
          history.push(
            `/dashboard/jobs-list/${
              userType === 'doctor' ? 'my-jobs' : 'jobs-to-review'
            }/${jobStage}?fromEditor=true`
          );
          notification['success']({
            message: 'Success',
            description: response.data.message
          });
        } else {
          setConfirmLoading(false);
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => {
        console.log(err);
        setConfirmLoading(false);
      });
  };

  const handelReleaseJob = jobId => {
    setReleaseJobId(jobId);
    setReleaseJobModalVisible(true);
  };
  const releaseJobConfirmHandler = () => {
    releaseReviewerJob(releaseJobId, user.sessionId)
      .then(response => {
        if (response.data.success) {
          notification['success']({
            message: 'Job released',
            description: response.data.message
          });
          setReleaseJobModalVisible(false);
          history.push(`/dashboard/jobs-list/available`);
        } else {
          notification['error']({
            message: 'Section Fetch Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const menu = (
    <Menu
      items={[
        {
          label: (
            <StyledMenuButton
              shape='round'
              reject={'true'}
              onClick={() => {
                handelReleaseJob(jobId);
              }}
              hidden={userType !== 'reviewer'}
            >
              {'Release'}
            </StyledMenuButton>
          ),
          key: '0'
        }
      ]}
    />
  );

  return loading && (editorLoading || demographicsLoading) ? (
    <Spinner />
  ) : (
    <>
      {/* <PageTitle title='Transcription Editor' /> */}
      <ContainerRow>
        <Row justify='space-between'>
          <Col xs={14} sm={14} md={14} lg={14}>
            <TitleRow>
              <Col span={24}>
                <CustomTypography color={theme['@text-color']}>
                  {userType === 'transcriptionist'
                    ? 'Job Editor'
                    : 'Job Review'}
                </CustomTypography>
              </Col>
              <Col span={24}>
                <BreadCrumbComponent items={BreadCrumbItems} />
              </Col>
            </TitleRow>
          </Col>
          <CustomHeadingCol
            xs={4}
            sm={4}
            md={4}
            lg={3}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <ActionButton
              type='primary'
              shape='round'
              onClick={confirmCompleteOrApprove}
              hidden={jobStage === 'in-review' || jobStage === 'completed'}
            >
              {userType === 'doctor' ? 'Complete' : 'Approve'}
            </ActionButton>
          </CustomHeadingCol>

          {userType === 'reviewer' && (
            <>
              <CustomHeadingCol xs={4} sm={4} md={4} lg={3}>
                <ActionButton
                  shape='round'
                  reject={'true'}
                  onClick={confirmRejectorAssignBack}
                  // hidden={viewOnly}
                >
                  Reject
                </ActionButton>
              </CustomHeadingCol>

              <CustomHeadingCol xs={3} sm={3} md={3} lg={2}>
                <Dropdown overlay={menu}>
                  <CustomMoreButtonStyle
                    type='primary'
                    shape='round'
                    icon={<MoreHorizIcon />}
                  />
                </Dropdown>
              </CustomHeadingCol>
            </>
          )}
        </Row>

        <DividerStyle />
        <HeadingRow gutter={[12, 5]}>
          <Col span={24}>
            <CustomAudioPlayer
              background={theme['@primary-color']}
              listenInterval={2000}
              ref={audioRef}
              src={audioUrl}
              onListen={event => {
                audioUpdate(event.target.currentTime);
              }}
              onPause={event => {
                audioUpdate(event.target.currentTime);
              }}
            />
          </Col>
          <Col span={24}>
            <DictationTemplateContainer>
              <DictationTemplateHeader>
                Dictation template(s)
              </DictationTemplateHeader>
              <DictationTemplateContent>
                {dectationTemplates.map((dectationTemplate, index) => {
                  return index > 0
                    ? `, ${dectationTemplate}`
                    : dectationTemplate;
                })}
              </DictationTemplateContent>
            </DictationTemplateContainer>
          </Col>
        </HeadingRow>
        <ContentRow gutter={[24, 24]}>
          <Col span={18}>
            <NewEditorComponent
              jobId={jobId}
              demographicsData={demographicsData}
              sections={sections}
              setSections={setSections}
              headings={headings}
              inferenceActive={inferenceActive}
              setInferenceActive={setInferenceActive}
              audioRef={audioRef}
              deleteSectionHandler={deleteSectionHandler}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              saveStatus={saveStatus}
              setSaveStatus={setSaveStatus}
              setChangeStatus={setChangeStatus}
              jobName={jobName}
              loading={editorLoading}
              fetchContents={fetchContents}
              // inferenceAnnotation={inferenceAnnotation}
              // setInferenceAnnotation={setInferenceAnnotation}
              lastUpdateTime={lastUpdateTime}
              setLastUpdateTime={setLastUpdateTime}
              // saveTimeFormat={saveTimeFormat}
              // setEditorDataPreviewValue={setEditorDataPreviewValue}
              // setEditorData={setEditorData}
              setEditorLoading={setEditorLoading}
              formatTime={formatTime}
              activeSectionContent={activeSectionContent}
              setActiveSectionContent={setActiveSectionContent}
              viewOnly={false}
              localSectionIdMap={localSectionIdMap}
              newInferenceActive={newInferenceActive}
              setNewInferenceActive={setNewInferenceActive}
              setRefetchDemographics={setRefetchDemographics}
            />
          </Col>
          <Col span={6}>
            <Demographics
              theme={theme}
              editorData={editorDataPreviewValue}
              content={inferenceActive ? inferenceData : demographicsData}
              inference={inferenceActive}
              loading={demographicsLoading}
              fetchContents={fetchContents}
              updateInferenceData={updateInferenceData}
              updateInferenceFlag={updateInferenceFlag}
              inferenceAnnotation={inferenceAnnotation}
              setInferenceAnnotation={setInferenceAnnotation}
              activeSection={activeSection}
              emptySection={emptySection}
              updateInferenceLoading={updateInferenceLoading}
              setUpdateInferenceLoading={setUpdateInferenceLoading}
              viewOnly={false}
            />
          </Col>
        </ContentRow>
      </ContainerRow>
      <ConfirmationModal
        visible={
          confirmationModal || completeTranscription || rejectTranscription
        }
        // onOk={
        //   confirmationModal
        //     ? deleteConfirmationHandler
        //     : completeTranscription
        //     ? completeOrApprove
        //     : reject0rAssignBack
        // }
        // onOk={() => {
        //   console.log('hi...'); // This will execute when the modal is confirmed
        //   userType === 'doctor' ? completeOrApprove() : reject0rAssignBack(); // Ensure the function is correctly executed
        // }}
        onOk={completeTranscription ? completeOrApprove : reject0rAssignBack}
        onCancel={deleteCancelHandler}
        antdModalProps={{ centered: true, width: '300', closable: false }}
        loading={confirmLoading}
        confirmation={true}
        buttonDisabled={
          !confirmationModal && !completeTranscription && comments.length <= 0
        }
      >
        {confirmationModal ? (
          <DeleteIcon />
        ) : completeTranscription ? (
          <ConfirmationIcon width={'66'} height={'66'} />
        ) : (
          <RejectIcon />
        )}

        {confirmationModal && (
          <p>Are you sure you would want to delete this section?</p>
        )}
        {completeTranscription && (
          <p>
            Are you sure you want to{' '}
            {userType === 'doctor' ? 'save' : 'approve'} this job?
          </p>
        )}
        {rejectTranscription && (
          <p>
            Are you sure you want to{' '}
            {userType === 'ROLE_DOCTOR' ? 'reject' : 'assign back'} this job?
          </p>
        )}
        {rejectTranscription && (
          <StyledTextArea
            placeholder='Enter Your Comments'
            rows={4}
            onChange={event => {
              setComments(event.target.value);
            }}
          />
        )}
      </ConfirmationModal>
      <ReleaseJobModal
        releaseJobModalVisible={releaseJobModalVisible}
        setReleaseJobModalVisible={setReleaseJobModalVisible}
        onConfirm={releaseJobConfirmHandler}
      />
      <SuccessModal
        visible={successModal}
        onOk={() => {
          setSuccessModal(false);
        }}
        antdModalProps={{ centered: true, width: '300', closable: false }}
        message='Section removed!'
      />
    </>
  );
};

export default NewEditor;
