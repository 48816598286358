import React, { useState, useEffect } from 'react';
import {
  FormStyle,
  FormDiv,
  FormContent,
  LoginFormStyle,
  LoginButtonStyle,
  ForgotPasswordStyle,
  ForgotPasswordColumn,
  SignUpButtonRow,
  FooterStyle,
  FooterText,
  TermsAndConditionsDiv,
  CheckboxDiv,
  CheckboxContent
} from './styledComponents';
import { Form, Row, Col, notification, Checkbox } from 'antd';
import FloatLabel from '../CustomComponents/FloatingLabel';
import { useDispatch, useSelector } from 'react-redux';
import { loginAPI, signUp } from '../../Apis/Auth';
import { AddUser } from '../../Redux/Actions/User';
import { useHistory, Link } from 'react-router-dom';
import CustomTypography from '../CustomComponents/CustomTypography';
import PrivacyPolicyModal from '../CustomComponents/PrivacyPolicyModal';

const LoginForm = ({ signup, newUser }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [theme] = useSelector(state => [state.themeReducer]);
  const [modalVisible, setModalVisible] = useState(false);
  const [baaModalVisible, setBaaModalVisible] = useState(false);

  const SubmitForm = values => {
    setLoading(true);

    if (signup) {
      signUp(values)
        .then(response => {
          if (response.data.success === true) {
            const id = response.data.result[0];
            setLoading(false);
            history.push(`/otp-verification?id=${id}`);
          } else {
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
            setLoading(false);
          }
        })
        .catch(err => {
          setLoading(false);
        });
    } else {
      loginAPI(values)
        .then(response => {
          if (response.data.success === true) {
            const data = response.data.result[0];
            const token = data.token;
            dispatch(AddUser(data));
            localStorage.setItem('user', JSON.stringify(data));
            localStorage.setItem('loggedIn', true);
            if (token) {
              localStorage.setItem('accessToken', token);
            } else {
              console.error('Token is missing in response');
            }
            if (data.userType === 'admin' && data.clinicConfigured === false) {
              history.push('/dashboard/settings/ehr-configuration');
            } else {
              if (data.userType === 'biller') {
                history.push('/dashboard/biller');
              } else if (data.userType === 'cascadeGod') {
                history.push('/dashboard/dictionary');
              } else {
                history.push('/dashboard');
              }
            }
            setLoading(false);
          } else {
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
            setLoading(false);
          }
        })
        .catch(err => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line
  }, [signup]);

  return (
    <FormDiv>
      <FormStyle
        span={16}
        xs={{ span: 14 }}
        sm={{ span: 14 }}
        md={{ span: 10 }}
        lg={{ span: 10 }}
      >
        {signup ? (
          <span>
            <CustomTypography color={theme['@text-color']}>
              Welcome To&nbsp;
            </CustomTypography>
            <CustomTypography color={theme['@primary-color']}>
              Cascade MD
            </CustomTypography>
          </span>
        ) : newUser ? (
          <>
            <CustomTypography color={theme['@text-color']}>
              Congratulations!
            </CustomTypography>
            <CustomTypography color={theme['@text-color']}>
              Welcome To&nbsp;
              <span style={{ color: '#49a4ce' }}>Cascade MD</span>
            </CustomTypography>
          </>
        ) : (
          <CustomTypography color={theme['@text-color']}>
            Hey Welcome Back!
          </CustomTypography>
        )}

        <FormContent>
          <Row justify='center'>
            <Col span={24}>
              <LoginFormStyle
                layout='vertical'
                form={form}
                onFinish={SubmitForm}
              >
                <Row gutter={[24, 12]}>
                  <Col span={24}>
                    <Form.Item
                      name='email'
                      rules={[
                        { required: true, message: 'Email is required' },
                        {
                          type: 'email',
                          message: 'Please enter valid Email'
                        }
                      ]}
                    >
                      <FloatLabel label='Email' type='input' />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name='password'
                      rules={[
                        { required: true, message: 'Password is required' }
                      ]}
                    >
                      <FloatLabel label='Password' type='password' />
                    </Form.Item>
                  </Col>

                  {signup && (
                    <>
                      <Col span={24}>
                        <Form.Item
                          name='confirm'
                          dependencies={['password']}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: 'Please confirm your password!'
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue('password') === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  'The two passwords that you entered do not match!'
                                );
                              }
                            })
                          ]}
                        >
                          <FloatLabel
                            label='Re-enter Password'
                            type='password'
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name='privacy-policy'
                          valuePropName='checked'
                          rules={[
                            {
                              validator: (_, value) =>
                                value
                                  ? Promise.resolve()
                                  : Promise.reject(
                                      new Error(
                                        'Please accept our Privacy Policy and BAA'
                                      )
                                    )
                            }
                          ]}
                        >
                          <CheckboxDiv>
                            <div>
                              <Checkbox />
                            </div>
                            <CheckboxContent>
                              Please accept our&nbsp;
                              <TermsAndConditionsDiv
                                color={theme['@primary-color']}
                                onClick={() => setModalVisible(true)}
                              >
                                Privacy Policy&nbsp;
                              </TermsAndConditionsDiv>
                              and&nbsp;
                              <TermsAndConditionsDiv
                                color={theme['@primary-color']}
                                onClick={() => setBaaModalVisible(true)}
                              >
                                BAA
                              </TermsAndConditionsDiv>
                            </CheckboxContent>
                          </CheckboxDiv>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  <SignUpButtonRow>
                    <Col span={12}>
                      <LoginButtonStyle
                        type='primary'
                        htmlType='submit'
                        loading={loading}
                      >
                        {signup ? 'Signup' : 'Login'}
                      </LoginButtonStyle>
                    </Col>
                    <ForgotPasswordColumn span={12}>
                      <ForgotPasswordStyle>
                        <Link to='/forgot-password'>Forgot Password?</Link>
                      </ForgotPasswordStyle>
                    </ForgotPasswordColumn>
                  </SignUpButtonRow>
                </Row>
              </LoginFormStyle>
            </Col>
          </Row>
        </FormContent>
      </FormStyle>
      <FooterStyle>
        <div>
          {signup ? (
            <FooterText>
              Already have an account?<Link to='/login'> Sign In</Link>
            </FooterText>
          ) : (
            <FooterText>
              Dont have an account?<Link to='/signup'> Sign Up</Link>
            </FooterText>
          )}
        </div>

        <div>
          <a
            href='https://www.cascade.md/contact/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Contact Us
          </a>
        </div>
      </FooterStyle>
      <PrivacyPolicyModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        baaModalVisible={baaModalVisible}
        setBaaModalVisible={setBaaModalVisible}
      />
    </FormDiv>
  );
};

export default LoginForm;
