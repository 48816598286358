import DashboardIcon from '../../../../Assets/Icons/DashboardIcon';
import SettingsIcon from '../../../../Assets/Icons/SettingsIcon';
import DoctorsIcon from '../../../../Assets/Icons/DoctorsIcon';
import TranscriptionistsIcon from '../../../../Assets/Icons/TranscriptionistsIcon';
import ReviewersIcon from '../../../../Assets/Icons/ReviewersIcon';
import DataIcon from '../../../../Assets/Icons/DataIcon';
import ToolIcon from '../../../../Assets/Icons/ToolIcon';
import ReportsIcon from '../../../../Assets/Icons/ReportsIcon';
import AvailableJobsIcon from '../../../../Assets/Icons/AvailableJobsIcon';
import BillingsIcon from '../../../../Assets/Icons/BillingsIcon';
import AuditIcon from '../../../../Assets/Icons/AuditIcon';
import BillersIcon from '../../../../Assets/Icons/BillersIcon';
import AdminsIcon from '../../../../Assets/Icons/AdminsIcon';
import RosterIcon from '../../../../Assets/Icons/RosterIcon';
import DictionaryIcon from '../../../../Assets/Icons/DictionaryIcon';

const clinicAdminSidebarConfig = [
  {
    key: '0',
    name: 'Dashboard',
    link: '/dashboard',
    icon: DashboardIcon
  },

  {
    key: '1',
    name: 'Admins',
    link: '/dashboard/admins',
    icon: AdminsIcon
  },
  {
    key: '2',
    name: 'Doctors',
    link: '/dashboard/doctors',
    icon: DoctorsIcon
  },
  // {
  //   key: '3',
  //   name: 'Transcriptionists',
  //   link: '/dashboard/transcriptionists',
  //   icon: TranscriptionistsIcon
  // }
  {
    key: '4',
    name: 'Reviewers',
    link: '/dashboard/reviewers',
    icon: ReviewersIcon
  },
  {
    key: '5',
    name: 'Billers',
    link: '/dashboard/Billers',
    icon: BillersIcon
  },
  {
    key: '6',
    name: 'Jobs',
    link: '/dashboard/jobs-list/ready',
    icon: AvailableJobsIcon
  },

  {
    key: '7',
    name: 'Audit',
    link: '/dashboard/audit',
    icon: AuditIcon
  },
  {
    key: '8',
    name: 'Settings',
    link: '/dashboard/settings',
    icon: SettingsIcon
  },
  {
    key: '9',
    name: 'Billing',
    link: '/dashboard/billing',
    icon: BillingsIcon
  },
  {
    key: '10',
    name: 'Roster',
    link: '/dashboard/roaster',
    icon: RosterIcon
  }
];
const billersSidebarConfig = [
  {
    key: '10',
    name: 'Jobs',
    link: '/dashboard/biller',
    icon: BillingsIcon
  },
  {
    key: '2',
    name: 'Audit',
    link: '/dashboard/biller-audit',
    icon: AuditIcon
  },
  {
    key: '3',
    name: 'Roster',
    link: '/dashboard/roaster',
    icon: RosterIcon
  }
];
const adminSidebarConfig = [
  {
    key: '0',
    name: 'Dashboard',
    link: '/dashboard',
    icon: DashboardIcon
  },
  {
    key: '1',
    name: 'Admins',
    link: '/dashboard/admins',
    icon: AdminsIcon
  },
  {
    key: '2',
    name: 'Doctors',
    link: '/dashboard/doctors',
    icon: DoctorsIcon
  },
  // {
  //   key: '3',
  //   name: 'Transcriptionists',
  //   link: '/dashboard/transcriptionists',
  //   icon: TranscriptionistsIcon
  // },
  {
    key: '4',
    name: 'Reviewers',
    link: '/dashboard/reviewers',
    icon: ReviewersIcon
  },

  {
    key: '5',
    name: 'Jobs',
    link: '/dashboard/jobs-list/available',
    icon: AvailableJobsIcon
  },

  {
    key: '6',
    name: 'Audit',
    link: '/dashboard/audit',
    icon: AuditIcon
  },
  {
    key: '7',
    name: 'Settings',
    link: '/dashboard/settings',
    icon: SettingsIcon
  },
  {
    key: '8',
    name: 'Billing',
    link: '/dashboard/billing',
    icon: BillingsIcon
  },
  {
    key: '9',
    name: 'Roster',
    link: '/dashboard/roaster',
    icon: RosterIcon
  }
];
const cascadeGodSidebarConfig = [
  {
    key: '1',

    name: 'Dictionary',

    link: '/dashboard/dictionary',

    icon: AdminsIcon
  }
];
// const transcriptionistSidebarConfig = [
//   {
//     key: '0',
//     name: 'Dashboard',
//     link: '/dashboard',
//     icon: DashboardIcon
//   },
//   {
//     key: '1',
//     name: 'Data',
//     link: '/dashboard/data',
//     icon: DataIcon,
//     disabled: true
//   },
//   {
//     key: '2',
//     name: 'Tool',
//     link: '/dashboard/tool',
//     icon: ToolIcon,
//     disabled: true
//   },
//   {
//     key: '3',
//     name: 'Reports',
//     link: '/dashboard/reports',
//     icon: ReportsIcon,
//     disabled: true
//   },
//   {
//     key: '4',
//     name: 'Settings',
//     link: '/dashboard/settings',
//     icon: SettingsIcon
//   }
// ];

const reviewerSidebarConfig = [
  {
    key: '0',
    name: 'Dashboard',
    link: '/dashboard',
    icon: DashboardIcon
  },
  {
    key: '1',
    name: 'Jobs',
    link: '/dashboard/jobs-list/available',
    icon: AvailableJobsIcon
  },
  // {
  //   key: '1',
  //   name: 'Data',
  //   link: '/dashboard/data',
  //   icon: DataIcon,
  //   disabled: true

  // },
  // {
  //   key: '2',
  //   name: 'Tool',
  //   link: '/dashboard/tool',
  //   icon: ToolIcon,
  //   disabled: true
  // },
  // {
  //   key: '3',
  //   name: 'Reports',
  //   link: '/dashboard/reports',
  //   icon: ReportsIcon,
  //   disabled: true
  // },
  {
    key: '4',
    name: 'Settings',
    link: '/dashboard/settings',
    icon: SettingsIcon
  }
];

const doctorSidebarConfig = [
  {
    key: '0',
    name: 'Dashboard',
    link: '/dashboard',
    icon: DashboardIcon
  },
  {
    key: '1',
    name: 'Jobs',
    link: '/dashboard/jobs-list/available',
    icon: AvailableJobsIcon
  },
  {
    key: '2',
    name: 'Audit',
    link: '/dashboard/audit',
    icon: AuditIcon
  },
  {
    key: '3',
    name: 'Settings',
    link: '/dashboard/settings',
    icon: SettingsIcon
  },
  {
    key: '4',
    name: 'Roster',
    link: '/dashboard/roaster',
    icon: RosterIcon
  }
];

const sidebarConfigMap = new Map([
  ['admin', adminSidebarConfig],
  // ['transcriptionist', transcriptionistSidebarConfig],
  ['reviewer', reviewerSidebarConfig],
  ['doctor', doctorSidebarConfig],
  ['clinicAdmin', clinicAdminSidebarConfig],
  ['biller', billersSidebarConfig],
  ['cascadeGod', cascadeGodSidebarConfig]
]);

export const sideMenuContents = userType => sidebarConfigMap.get(userType);
