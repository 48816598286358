const doctorJobListSideBarConfig = [
  {
    key: '0',
    name: 'In Progress',
    countValueKey: 'availableJobs',
    path: '/dashboard/jobs-list/my-jobs/in-progress'
  },
  {
    key: '1',
    name: 'In Review',
    countValueKey: 'inProgressJobs',
    path: '/dashboard/jobs-list/my-jobs/in-review'
  },
  {
    key: '2',
    name: 'Completed',
    countValueKey: 'inCompleteJobs',
    path: '/dashboard/jobs-list/my-jobs/complete'
  },
  {
    key: '3',
    name: 'Incomplete',
    countValueKey: 'inCompleteJobs',
    path: '/dashboard/jobs-list/my-jobs/incomplete'
  }
];

const reviewerJobListSideBarConfig = [
  {
    key: '4',
    name: 'Ready ',
    countValueKey: 'availableTranscriptions',
    path: '/dashboard/jobs-list/jobs-to-review/ready'
  },
  {
    key: '0',
    name: 'In Progress',
    countValueKey: 'inProgressTranscriptions',
    path: '/dashboard/jobs-list/jobs-to-review/in-progress'
  }
  // {
  //   key: '5',
  //   name: 'Incomplete',
  //   countValueKey: 'inCompleteTranscriptions',
  //   path: '/dashboard/jobs-list/incomplete'
  // }
];

const jobListContentsMap = new Map([
  ['doctor', doctorJobListSideBarConfig],
  ['reviewer', reviewerJobListSideBarConfig]
]);

export const sideBarContents = userType => jobListContentsMap.get(userType);
