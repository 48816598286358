import DoctorsIcon from '../../../Assets/Icons/DoctorsIcon';
// import TranscriptionistsIcon from '../../../Assets/Icons/TranscriptionistsIcon';
import ReviewersIcon from '../../../Assets/Icons/ReviewersIcon';
import AvailableJobsIcon from '../../../Assets/Icons/AvailableJobsIcon';
import ReadyJobsIcon from '../../../Assets/Icons/ReadyJobsIcon';
import InProgressIcon from '../../../Assets/Icons/InProgressIcon';
import CompletedJobsIcon from '../../../Assets/Icons/CompletedJobsIcon';
import RejectedJobsIcon from '../../../Assets/Icons/RejectedJobsIcon';
import AdminsIcon from '../../../Assets/Icons/AdminsIcon';
import IncompleteJobsIcon from '../../../Assets/Icons/IncompleteJobsIcon';

const adminDashboardContents = [
  {
    title: 'My Jobs',
    background: '#FAE3BF',
    iconColor: '#F9A218',
    icon: AvailableJobsIcon,
    path: '/dashboard/jobs-list/available',
    count: 0,
    countValue: 'myJobs'
  },
  {
    title: 'Admins',
    background: '#f7f8f9',
    iconColor: '#898996',
    icon: AdminsIcon,
    path: '/dashboard/admins',
    countValue: 'adminCount',
    count: 0
  },
  {
    title: 'Doctors',
    background: '#FAE3BF',
    iconColor: '#F9A218',
    icon: DoctorsIcon,
    path: '/dashboard/doctors',
    count: 0,
    countValue: 'doctorCount'
  },
  // {
  //   title: 'Transcriptionists',
  //   background: '#C8E6FA',
  //   iconColor: '#4697CE',
  //   icon: TranscriptionistsIcon,
  //   path: '/dashboard/transcriptionists',
  //   count: 0,
  //   countValue: 'transcriptionistCount'
  // },
  {
    title: 'Reviewers',
    background: '#D8F1C7',
    iconColor: '#9DC383',
    icon: ReviewersIcon,
    path: '/dashboard/reviewers',
    count: 0,
    countValue: 'reviewerCount'
  }
];

// const godUserDashboardContents = [
//   {
//     title: 'My Jobs',
//     background: '#FAE3BF',
//     iconColor: '#F9A218',
//     icon: AvailableJobsIcon,
//     path: '/dashboard/dictionary',
//     count: 0,
//     countValue: 'myJobs'
//   }
// ];

const transcriptionistDashboardContents = [
  {
    title: 'My Job(s)',
    background: '#C8E6FA',
    iconColor: '#4697CE',
    icon: AvailableJobsIcon,
    path: '/dashboard/jobs-list/available',
    count: 0,
    countValue: 'myJobs'
  },
  {
    title: 'Ready Job(s)',
    background: '#C7CCF4',
    iconColor: '#7582E5',
    icon: ReadyJobsIcon,
    path: '/dashboard/jobs-list/ready',
    count: 0,
    countValue: 'availableJobs'
  },
  {
    title: 'In Progress Job(s)',
    background: '#FEF5C9',
    iconColor: '#CCB64C',
    icon: InProgressIcon,
    path: '/dashboard/jobs-list/in-progress',
    count: 0,
    countValue: 'inProgressJobs'
  },

  {
    title: 'Incomplete Job(s)',
    background: '#FFE87D',
    iconColor: '#C67E0C',
    icon: IncompleteJobsIcon,
    path: '/dashboard/jobs-list/incomplete',
    count: 0,
    countValue: 'inCompleteJobs'
  }
];

const reviewerDashboardContents = [
  {
    title: 'My Jobs',
    background: '#FAE3BF',
    iconColor: '#F9A218',
    icon: AvailableJobsIcon,
    path: '/dashboard/jobs-list/available',
    count: 0,
    countValue: 'myJobs'
  },
  {
    title: 'Ready Transcription(s)',
    background: '#C7CCF4',
    iconColor: '#7582E5',
    icon: ReadyJobsIcon,
    path: '/dashboard/jobs-list/ready',
    count: 0,
    countValue: 'availableTranscriptions'
  },
  {
    title: 'In Progress Transcription(s)',
    background: '#FEF5C9',
    iconColor: '#CCB64C',
    icon: InProgressIcon,
    path: '/dashboard/jobs-list/in-progress',
    count: 0,
    countValue: 'inProgressTranscriptions'
  },
  {
    title: 'Approved Transcription(s)',
    background: '#D8F1C7',
    iconColor: '#79B152',
    icon: CompletedJobsIcon,
    path: '/dashboard/jobs-list/completed',
    count: 0,
    countValue: 'approvedTranscriptions'
  },
  {
    title: 'Incomplete Job(s)',
    background: '#FFE87D',
    iconColor: '#C67E0C',
    icon: IncompleteJobsIcon,
    path: '/dashboard/jobs-list/incomplete',
    count: 0,
    countValue: 'inCompleteTranscription'
  }
];

const dashboardContentsMap = new Map([
  ['admin', adminDashboardContents],
  ['transcriptionist', transcriptionistDashboardContents],
  ['reviewer', reviewerDashboardContents],
  ['doctor', transcriptionistDashboardContents],
  ['clinicAdmin', adminDashboardContents]
  // ['cascadeGod', godUserDashboardContents]
]);

export const dashboardContents = userType => dashboardContentsMap.get(userType);
