import React, { useEffect, useState } from 'react';
import {
  Row,
  Form,
  Input,
  Col,
  Select,
  notification,
  Popconfirm,
  Divider,
  Switch,
  Button,
  Tooltip
} from 'antd';
import { SubmitButton, SubmitButtonCol } from '../styledComponents.js';
import {
  CancelButton,
  HeadingButtonContainer,
  MappedValues,
  AddButton,
  SectionHeading,
  AddHeadingContainer,
  SmallCancelButton,
  SmallSaveButton,
  MappedValuesContainer,
  HeadingDeleteButton,
  TemplateDetailedSection,
  SelectedHeadingValues,
  SelectedItemsListContainer,
  RestrictColumImport,
  HeadRow,
  TemplateDrivenCol,
  InputTextArea,
  InputTextOverallArea,
  LineInput
} from './styledComponents.js';
import 'react-phone-input-2/lib/style.css';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import BiDirectionalArrow from '../../../Assets/Icons/BiDirectionalArrow.js';
import { PopoverGlobalStyle } from '../JobList/styledComponents.js';
import { SwapOutlined } from '@ant-design/icons';
import { Container } from '../Billing/PaymentLog/styledComponents.js';
import {
  DictationCol,
  SectionHeading2,
  TemplateButton
} from '../Dictation/styledComponents.js';
const { Option } = Select;
const { TextArea } = Input;
const AddDictioaryTemplatesForm = ({
  supportedSections,
  selectedItem,
  submitHandler,
  setAddDrawerVisible,
  setSelectedItem,
  saveLoading
}) => {
  const [form] = Form.useForm();
  const [showAddNew, setShowAddNew] = useState(false);
  const [customUIHeading, setCustomUIHeading] = useState('');
  const [systemHeading, setSystemHeading] = useState(null);
  const [sectionHeadings, setSectionHeadings] = useState([]);
  const [tooltipDescription, setTooltipDescription] = useState('');
  const [defaultValue, setDefaultValue] = useState('');
  const [showAddHeading, setShowAddHeading] = useState(false);

  const deleteHeading = index => {
    sectionHeadings.splice(index, 1);
    setSectionHeadings([...sectionHeadings]);
  };
  const TruncatedText = ({ text, maxLength }) => {
    if (typeof text === 'string' && text.length > maxLength) {
      const truncatedText = text.substring(0, maxLength) + '...';
      return (
        <Tooltip content={text}>
          <span title={text}>{truncatedText}</span>
        </Tooltip>
      );
    }
    return (
      <Tooltip content={text}>
        <span title={text}>{text}</span>
      </Tooltip>
    );
  };
  const handleSave = () => {
    onSave(); // Call the onSave function passed as a prop
    setShowAddHeading(false); // Hide the AddHeadingContainer after saving
  };

  const handleCancel = () => {
    setCustomUIHeading('');
    setSystemHeading('');
    setRequired(false);
    setDefaultValue('');
    setTooltipDescription('');
    setShowAddHeading(false); // Hide the AddHeadingContainer on cancel
  };
  const onSave = () => {
    let newHeading = customUIHeading.trim();

    if (newHeading === '') {
      notification['error']({
        message: 'Failed',
        description: 'Section heading is mandatory'
      });
    } else if (!systemHeading) {
      notification['error']({
        message: 'Failed',
        description: 'Mapping value is mandatory'
      });
    } else {
      let isHeadingPresent = sectionHeadings.find(
        heading =>
          heading.customUIHeading.toLowerCase() === newHeading.toLowerCase()
      );
      if (isHeadingPresent) {
        notification['error']({
          message: 'Failed',
          description: 'Duplicate heading'
        });
      } else {
        let obj = {
          customUIHeading: newHeading,
          systemHeading: systemHeading,
          description: tooltipDescription,
          defaultValue: defaultValue,
          required: required
        };
        sectionHeadings.push(obj);
        setSectionHeadings(sectionHeadings);
        setSystemHeading(null);
        setCustomUIHeading(null);
        setRequired(false);
        setTooltipDescription('');
        setDefaultValue('');
        setShowAddNew(false);
      }
    }
  };
  // const [templateDrivenFlowEnabled, setTemplateDrivenFlowEnabled] = useState(
  //   selectedItem ? selectedItem.templateDrivenFlowEnabled : false
  // );
  const [required, setRequired] = useState(
    selectedItem ? selectedItem.required : false
  );
  const onCancel = () => {
    if (selectedItem) setSectionHeadings([...selectedItem.sectionHeadings]);
    setAddDrawerVisible(false);
    setSelectedItem(null);
  };
  const CustomLabelRequired = () => (
    <div>
      <p
        style={{
          height: '18px',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '18.2px',
          color: '#080808'
        }}
      >
        Required
      </p>
    </div>
  );
  // const CustomLabelDirectional = () => (
  //   <div>
  //     <p
  //       style={{
  //         height: '18px',
  //         fontWeight: 600,
  //         fontSize: '14px',
  //         lineHeight: '18.2px',
  //         color: '#080808'
  //       }}
  //     >
  //       Directional Flow
  //     </p>
  //     <p
  //       style={{
  //         fontSize: '12px',
  //         lineHeight: '15.6px',
  //         color: '#5C5C5C'
  //       }}
  //     >
  //       When enabled, the headings can be completed in the order.
  //     </p>
  //   </div>
  // );
  useEffect(() => {
    if (selectedItem) {
      form.setFieldsValue(selectedItem);
      setSectionHeadings([...selectedItem.sectionHeadings]);
    }
    // eslint-disable-next-line
  }, [selectedItem]);
  const CustomLabel = () => (
    <div>
      <p
        style={{
          height: '18px',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '18.2px',
          color: '#080808'
        }}
      >
        Description
      </p>
    </div>
  );
  const CustomLabelone = () => (
    <div>
      <p
        style={{
          height: '18px',
          fontWeight: 700,
          fontSize: '16px',
          lineHeight: '18.2px',
          color: '#080808'
        }}
      >
        Template Name
      </p>
    </div>
  );
  return (
    <Form
      form={form}
      layout='vertical'
      style={{ height: '100%' }}
      onFinish={values => {
        if (sectionHeadings.length > 0) {
          if (showAddHeading) {
            notification['error']({
              message: 'Failed',
              description: 'Save or cancel the new heading section'
            });
          } else submitHandler(values, sectionHeadings);
        } else
          notification['error']({
            message: 'Failed',
            description: 'Add atleast one section heading to save template'
          });
      }}
    >
      <HeadRow>
        <Col span={16}>
          <h2 className='templatecreation'>
            {selectedItem ? 'Edit Template ' : 'Create New Template '}
          </h2>
        </Col>

        <SubmitButtonCol span={8} style={{ textAlign: 'right' }}>
          <CancelButton
            htmlType='button'
            type='primary'
            size='large'
            onClick={onCancel}
          >
            Cancel
          </CancelButton>

          <SubmitButton
            htmlType='submit'
            type='primary'
            size='large'
            loading={saveLoading}
          >
            Save
          </SubmitButton>
        </SubmitButtonCol>
      </HeadRow>
      <Divider />

      <Row justify='center'>
        <Col span={24}>
          <Form.Item
            label='Template Name'
            name='templateName'
            validateTrigger={['onSubmit']}
            rules={[
              { required: true, message: 'Template Name is required' },
              {
                required: true,
                max: 250,
                pattern: new RegExp(/^[^\s][\w\W]{0,249}[^\s]$/g),
                message:
                  'Please enter valid name.(Name must not exceed 250 characters and must not contain leading and trailing space)'
              }
            ]}
          >
            <Input size='large' placeholder='Enter the name of the Template' />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label='Description'
            name='templateDescription'
            validateTrigger={['onSubmit']}
            rules={[
              {
                required: true,
                message: 'Template Description is required'
              },
              {
                required: true,
                max: 250,
                pattern: new RegExp(/^[^\s][\w\W]{0,249}[^\s]$/g),
                message:
                  'Please enter valid Description.(Description must not exceed 250 characters and must not contain leading and trailing space)'
              }
            ]}
          >
            <Input
              size='large'
              placeholder=' Enter the description of the Template'
            />
          </Form.Item>
        </Col>
        {/* 
        <TemplateDrivenCol span={24}>
          <Row style={{ marginBottom: '-60px' }}>
            <Col span={22}>
              <Form.Item label={<CustomLabelDirectional />}></Form.Item>
            </Col>

            <Col span={2}>
              <Form.Item
                name='templateDrivenFlowEnabled'
                style={{ marginBottom: 0, marginRight: '0px' }}
              >
                <Switch
                  checked={templateDrivenFlowEnabled}
                  onChange={() => {
                    form.setFieldValue({
                      ...form.getFieldsValue(),
                      templateDrivenFlowEnabled: !templateDrivenFlowEnabled
                    });
                    setTemplateDrivenFlowEnabled(!templateDrivenFlowEnabled);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </TemplateDrivenCol> */}

        <SectionHeading>
          <span className='headstyle'>Enter Heading</span>
        </SectionHeading>

        <div style={{ width: '100%' }}>
          {sectionHeadings && sectionHeadings.length > 0 ? (
            <div>
              <MappedValuesContainer>
                {sectionHeadings.map((item, index) => (
                  <InputTextOverallArea key={index}>
                    <MappedValues form={true}>
                      <span one={true}>
                        <TruncatedText
                          text={item.customUIHeading}
                          maxLength={30}
                        />
                      </span>
                      <SwapOutlined />
                      <span two={true}>
                        <TruncatedText
                          text={item.systemHeading}
                          maxLength={22}
                        />
                        <Popconfirm
                          title={<>Delete Heading </>}
                          onConfirm={() => {
                            deleteHeading(index);
                          }}
                          okText='Yes'
                          cancelText='No'
                          overlayClassName='popover-delete-confirm'
                        >
                          <PopoverGlobalStyle />
                          <HeadingDeleteButton type='button'>
                            <DeleteOutlined />
                          </HeadingDeleteButton>
                        </Popconfirm>
                      </span>
                    </MappedValues>
                    <hr
                      style={{
                        border: '1px solid #e0e0e0',
                        margin: '20px 0'
                      }}
                    />

                    <TemplateDrivenCol span={24}>
                      <Row style={{ marginBottom: '-60px' }}>
                        <Col span={22}>
                          <Form.Item
                            label={<CustomLabelRequired />}
                          ></Form.Item>
                        </Col>

                        <Col span={2}>
                          <Form.Item
                            name='required'
                            style={{
                              marginBottom: 0,
                              marginRight: '0px'
                            }}
                          >
                            <Switch checked={item.required ? true : false} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </TemplateDrivenCol>
                    {item.defaultValue && (
                      <div style={{ marginBottom: '10px' }}>
                        {/* <strong>Default Value:</strong> */}
                        <SectionHeading2>
                          <span className='headstyle2'>Default Value</span>
                        </SectionHeading2>
                        <TextArea
                          rows={4}
                          // value={item.defaultValue}
                          value={item.defaultValue}
                          style={{
                            backgroundColor: '#F0F2F5',
                            padding: '10px',
                            borderRadius: '8px',
                            marginTop: '5px',
                            width: '100%'
                          }}
                          readOnly
                        />
                      </div>
                    )}
                    {item.description && (
                      <div style={{ marginBottom: '10px' }}>
                        <SectionHeading2>
                          <span className='headstyle2'>Tooltip Text</span>
                        </SectionHeading2>
                        <TextArea
                          rows={4}
                          value={item.description}
                          placeholder='Tooltip Text'
                          style={{
                            backgroundColor: '#F0F2F5',
                            padding: '10px',
                            borderRadius: '8px',
                            marginTop: '5px',
                            width: '100%'
                          }}
                          readOnly
                        />
                      </div>
                    )}
                  </InputTextOverallArea>
                ))}
              </MappedValuesContainer>
            </div>
          ) : null}

          <>
            {showAddHeading && (
              <AddHeadingContainer>
                <Row
                  style={{
                    marginBottom: '10px',
                    backgroundColor: '#DDE3EB',
                    padding: '10px',
                    borderRadius: '8px',
                    alignItems: 'center'
                  }}
                >
                  <Col span={16}>
                    <h4 style={{ margin: 0 }}>New Heading</h4>
                  </Col>
                  <Col span={8} style={{ textAlign: 'right' }}>
                    <Button
                      onClick={handleCancel}
                      style={{ marginRight: '10px' }}
                    >
                      Cancel
                    </Button>
                    <Button type='primary' onClick={handleSave}>
                      Add
                    </Button>
                  </Col>
                </Row>
                <div
                  className='AddheadingOuterSection'
                  style={{
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                  }}
                >
                  <Col span={24}>
                    <Row>
                      <Input
                        size='large'
                        placeholder='Add heading'
                        onChange={e => {
                          setCustomUIHeading(e.target.value);
                        }}
                        style={{ flex: '1', marginRight: '5px' }}
                        value={customUIHeading}
                      />
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <SwapOutlined />
                      </div>
                      <Select
                        placeholder='Section'
                        onSelect={val => {
                          setSystemHeading(val);
                        }}
                        style={{ flex: '1', marginLeft: '5px' }}
                        value={systemHeading}
                      >
                        {supportedSections.map(item => {
                          return <Option value={item}>{item}</Option>;
                        })}
                      </Select>
                    </Row>
                    <Row>
                      <TemplateDrivenCol span={24}>
                        <Row style={{ marginBottom: '-60px' }}>
                          <Col span={22}>
                            <Form.Item
                              label={<CustomLabelRequired />}
                            ></Form.Item>
                          </Col>
                          <Col span={2}>
                            <Form.Item
                              name='required'
                              style={{
                                marginBottom: 0,
                                marginRight: '0px'
                              }}
                            >
                              <Switch
                                checked={required}
                                onChange={() => {
                                  form.setFieldValue({
                                    ...form.getFieldsValue(),
                                    required: !required
                                  });
                                  setRequired(!required);
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </TemplateDrivenCol>
                      <SectionHeading2>
                        <span className='headstyle2'>Tooltip Text</span>
                      </SectionHeading2>
                      <InputTextArea>
                        <Input.TextArea
                          rows={4}
                          placeholder='Add tooltip text (optional)'
                          onChange={e => setTooltipDescription(e.target.value)}
                          style={{ width: '100%' }}
                          value={tooltipDescription}
                        />
                      </InputTextArea>
                    </Row>
                    <Row>
                      <SectionHeading2>
                        <span className='headstyle2'>Default Text</span>
                      </SectionHeading2>
                      <InputTextArea>
                        <Input.TextArea
                          rows={4}
                          placeholder='Add default text (optional)'
                          onChange={e => setDefaultValue(e.target.value)}
                          style={{ width: '100%' }}
                          value={defaultValue}
                        />
                      </InputTextArea>
                    </Row>
                  </Col>
                </div>
              </AddHeadingContainer>
            )}
            {!showAddHeading && (
              <Col span={24}>
                <TemplateButton onClick={() => setShowAddHeading(true)}>
                  <span className='icon-wrapper'>
                    <PlusOutlined />
                  </span>
                  New Template
                </TemplateButton>
              </Col>
            )}
          </>
        </div>
      </Row>
    </Form>
  );
};

export default AddDictioaryTemplatesForm;
