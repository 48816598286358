import AdminsIcon from '../../../Assets/Icons/AdminsIcon';
import AvailableJobsIcon from '../../../Assets/Icons/AvailableJobsIcon';
import DoctorsIcon from '../../../Assets/Icons/DoctorsIcon';
import FacilityIcon from '../../../Assets/Icons/FacilityIcon';
import ReviewersIcon from '../../../Assets/Icons/ReviewersIcon';
// import TranscriptionistsIcon from '../../../Assets/Icons/TranscriptionistsIcon';

export const getSuperAdminDashboardConfig = dashboardData => {
  return [
    {
      heading: 'Admin',
      content: dashboardData?.adminCount,
      color: '#78828F',
      icon: AdminsIcon,
      path: '#'
    },
    {
      heading: 'Doctors',
      content: dashboardData?.doctorCount,
      color: '#47A4EA',
      icon: DoctorsIcon,
      path: '#'
    },
    // {
    //   heading: 'Transcriptionists',
    //   content: dashboardData?.transcriptionistCount,
    //   color: '#E277C2',
    //   icon: TranscriptionistsIcon,
    //   path: '#'
    // },
    {
      heading: 'Reviewers',
      content: dashboardData?.reviewerCount,
      color: '#FF8901',
      icon: ReviewersIcon,
      path: '#'
    },
    {
      heading: 'Open Jobs',
      content: dashboardData?.openJobCount,
      color: '#FB6464',
      icon: AvailableJobsIcon,
      path: '#'
    },
    {
      heading: 'Completed Jobs',
      content: dashboardData?.completedJobsCount,
      color: '#49C006',
      icon: AvailableJobsIcon,
      path: '#'
    },
    {
      heading: 'Total Facility',
      content: dashboardData?.facilityCount,

      color: '#01DDFE',
      icon: FacilityIcon,
      path: '#'
    }
  ];
};
