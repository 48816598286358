import React, { useEffect, useRef, useState } from 'react';
import { ContainerRow, TitleRow, DividerStyle } from '../styledComponents';
import {
  HeadingRow,
  ContentRow,
  ActionButton,
  StyledTextArea
} from './styledComponents';
import { useSelector } from 'react-redux';
import { Col, notification } from 'antd';
import { useParams } from 'react-router-dom';
import {
  downloadAudio,
  getDemographics,
  sectionFetch,
  getHeadings,
  deleteSection,
  getInference,
  updateAudioTime,
  updateInference,
  updateInferenceEditFlag
} from '../../../Apis/Jobs';
import {
  completeTranscriptorJob,
  rejectTranscriptorJob
} from '../../../Apis/Transcriptor';
import { completeReviewerJob, rejectReviewerJob } from '../../../Apis/Reviewer';
import PageTitle from '../../../Utils/PageTitle';
import CustomTypography from '../../CustomComponents/CustomTypography';
import BreadCrumbComponent from '../../CustomComponents/BreadCrumbComponent';
import CustomAudioPlayer from '../../CustomComponents/AudioPlayer';
import Demographics from './Demographics';
import Spinner from '../../CustomComponents/Spinner';
import EditorComponent from '../../CustomComponents/EditorComponent';
import ConfirmationModal from '../../CustomComponents/ConfirmationModal';
import SuccessModal from '../../CustomComponents/SuccessModal';
import { useHistory, useLocation } from 'react-router-dom';
import ConfirmationIcon from '../../../Assets/Icons/ConfirmationIcon';
import RejectIcon from '../../../Assets/Icons/RejectIcon';
import DeleteIcon from '../../../Assets/Icons/DeleteIcon';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../../Constants/StringConstants';
import { completeDoctorJob } from '../../../Apis/Doctor';

const Editor = () => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const { jobId } = useParams();
  const audioRef = useRef();
  const [audioUrl, setAudioUrl] = useState(null);
  const [demographicsData, setDemographicsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editorData, setEditorData] = useState([]);
  const [headings, setHeadings] = useState([]);
  const [inferenceActive, setInferenceActive] = useState(false);
  const [currentTime, setCurrentTime] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [completeTranscription, setCompleteTranscription] = useState(false);
  const [rejectTranscription, setRejectTranscription] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [demographicsLoading, setDemographicsLoading] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [inferenceData, setInferenceData] = useState([]);
  const [inferenceAnnotation, setInferenceAnnotation] = useState([]);
  const [saveStatus, setSaveStatus] = useState(null);
  const [changeStatus, setChangeStatus] = useState(false);
  const [comments, setComments] = useState('');
  const [jobName, setJobName] = useState('');
  const [editorLoading, setEditorLoading] = useState(false);
  const [lastSavedTime, setLastSavedTime] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [emptySection, setEmptySection] = useState(false);
  const [updateInferenceLoading, setUpdateInferenceLoading] = useState(false);
  const [editorDataPreviewValue, setEditorDataPreviewValue] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [userType, setUserType] = useState('');
  const [user] = useSelector(state => [state.userReducer]);

  useEffect(() => {
    if (changeStatus === true || saveStatus === 'Saving') {
      window.addEventListener('beforeunload', keepOnPage);
      return () => window.removeEventListener('beforeunload', keepOnPage);
    }
    // eslint-disable-next-line
  }, [saveStatus, changeStatus]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setUserType(searchParams.get('userType'));
    // eslint-disable-next-line
  }, []);

  const keepOnPage = event => {
    var message =
      "Warning!\n\nNavigating away from this page will delete your text if you haven't already saved it.";
    event.returnValue = message;
    return message;
  };

  const getAudio = () => {
    setLoading(true);
    downloadAudio(jobId)
      .then(response => {
        if (response.data.success === true) {
          getEditorData();
          setAudioUrl(response.data.result[0]);
          setLoading(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
          setLoading(false);
        }
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const getDemographicsData = () => {
    setDemographicsLoading(true);
    getDemographics(jobId)
      .then(response => {
        if (response.data.success === true) {
          setDemographicsData(response.data.result);
          setDemographicsLoading(false);
        } else {
          notification['error']({
            message: 'Demographics Fetch Failed',
            description: response.data.message
          });
          setDemographicsLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setDemographicsLoading(false);
      });
  };

  const getInferenceData = sectionId => {
    setDemographicsLoading(true);
    getInference(jobId, sectionId)
      .then(response => {
        if (response.data.success === true) {
          setInferenceData(response.data.result);
          setDemographicsLoading(false);
        } else {
          notification['error']({
            message: 'Inference Fetch Failed',
            description: response.data.message
          });
          setDemographicsLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setDemographicsLoading(false);
      });
  };

  const updateInferenceData = payload => {
    updateInference(jobId, activeSection, payload).then(response => {
      if (response.data.success) {
        fetchContents(activeSection);
      } else {
        notification['error']({
          message: 'Inference Update Failed',
          description: response.data.message
        });
      }
    });
  };

  const updateInferenceFlag = (payload, isFromTable) => {
    updateInferenceEditFlag(jobId, activeSection, payload)
      .then(response => {
        if (response.data.success) {
          !isFromTable && fetchContents(activeSection);
          setUpdateInferenceLoading(false);
        } else {
          notification['error']({
            message: 'Edit Flag Update Failed',
            description: response.data.message
          });
          setUpdateInferenceLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setUpdateInferenceLoading(false);
      });
  };

  const checkSectionEmpty = () => {
    let allSections = document.querySelectorAll('.section-content');
    let sectionEmpty = true;

    allSections.forEach(section => {
      if (section.id === activeSection) {
        if (section.innerText && section.innerText.trim().length > 0) {
          sectionEmpty = false;
        } else {
          sectionEmpty = true;
        }
      }
    });
    return sectionEmpty;
  };

  const fetchContents = (activeSect, fromSave) => {
    if (inferenceActive || fromSave) {
      if (activeSect && !activeSect.includes('new')) {
        let headingNode = document.getElementById(activeSect);
        let classNameSelector = document.getElementsByClassName('selected');
        for (var item of classNameSelector) {
          item.classList.remove('selected');
        }
        let sectionEmpty = checkSectionEmpty();
        if (sectionEmpty) {
          setEmptySection(true);
        } else {
          if (headingNode && headingNode.classList) {
            headingNode.classList.add('selected');
          }
          setEmptySection(false);
          getInferenceData(activeSect);
        }
      }
    } else if (Boolean(!inferenceActive) && Boolean(!fromSave)) {
      let classNameSelector = document.getElementsByClassName('selected');
      for (var i of classNameSelector) {
        i.classList.remove('selected');
      }
      getDemographicsData();
    }
  };

  const saveTimeFormat = time => {
    const savedTime = moment(time);
    const now = moment(new Date());
    const diff = now.diff(savedTime, 'hours');

    if (diff > 12) {
      setLastSavedTime(savedTime.format(DATE_TIME_FORMAT));
    } else {
      setLastSavedTime('Today ' + savedTime.format('HH:mm:ss'));
    }
  };

  const getEditorData = () => {
    setEditorLoading(true);
    sectionFetch(jobId)
      .then(response => {
        if (response.data.success) {
          setEditorData(
            response.data.result[0] && response.data.result[0].sections
          );
          setEditorDataPreviewValue(
            response.data.result[0] && response.data.result[0].sections
          );
          setCurrentTime(response.data.result[0].audioTime);
          setActiveSection(
            response.data.result[0].sections &&
              response.data.result[0].sections.length > 0 &&
              response.data.result[0].sections[0].id
          );
          setJobName(response.data.jobName);
          saveTimeFormat(response.data.result[0].lastUpdatedTime);
          setEditorLoading(false);
        } else {
          notification['error']({
            message: 'Section Fetch Failed',
            description: response.data.message
          });
          setEditorLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setEditorLoading(false);
      });
    getHeadings(jobId)
      .then(response => {
        if (response.data.success === true) {
          setHeadings(response.data.result);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (jobId) {
      getAudio();
    }
    // eslint-disable-next-line
  }, [jobId]);

  useEffect(() => {
    if (jobId) {
      fetchContents(activeSection);
    }
    // eslint-disable-next-line
  }, [selectedSection, inferenceActive]);

  useEffect(() => {
    if (currentTime) {
      if (audioRef.current && audioRef.current.audio.current) {
        audioRef.current.audio.current.currentTime = currentTime;
      }
    }
  }, [currentTime, audioRef]);

  const deleteSectionHandler = sectionId => {
    setSelectedSection(sectionId);
    setConfirmationModal(true);
  };

  const deleteConfirmationHandler = () => {
    setConfirmLoading(true);
    deleteSection(jobId, selectedSection)
      .then(response => {
        setConfirmLoading(false);
        if (response.data.success === true) {
          setActiveSection(null);
          setInferenceActive(false);
          getEditorData();
          setConfirmationModal(false);
          setSuccessModal(true);
          setSelectedSection(null);
        } else {
          setConfirmLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setConfirmLoading(false);
      });
  };

  const deleteCancelHandler = () => {
    setConfirmationModal(false);
    setCompleteTranscription(false);
    setRejectTranscription(false);
    setSelectedSection(null);
  };

  let BreadCrumbItems = [
    {
      name: 'Dashboard',
      path: '/dashboard'
    },
    {
      name: 'Jobs-list',
      path: '/dashboard/jobs-list/in-progress'
    },
    {
      name:
        userType === 'transcriptionist'
          ? 'Transcription Editor'
          : 'Transcription Review'
    }
  ];

  const confirmCompleteOrApprove = () => {
    setCompleteTranscription(true);
  };

  const confirmRejectorAssignBack = () => {
    setRejectTranscription(true);
  };

  const audioUpdate = audioTime => {
    if (audioTime !== 0) {
      updateAudioTime(jobId, audioTime)
        .then(response => {
          if (!response.data.success) {
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const completeOrApprove = () => {
    setConfirmLoading(true);
    userType === 'doctor' &&
      completeDoctorJob(jobId, user.sessionId)
        .then(response => {
          if (response.data.success) {
            setConfirmLoading(false);
            history.push(`/dashboard/jobs-list/completed`);
            notification['success']({
              message: 'Success',
              description: response.data.message
            });
          } else {
            setConfirmLoading(false);
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
          }
        })
        .catch(err => {
          console.log(err);
          setConfirmLoading(false);
        });

    userType === 'reviewer' &&
      completeReviewerJob(jobId, user.sessionId)
        .then(response => {
          if (response.data.success) {
            setConfirmLoading(false);
            history.push(`/dashboard/jobs-list/completed`);
            notification['success']({
              message: 'Success',
              description: response.data.message
            });
          } else {
            setConfirmLoading(false);
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
          }
        })
        .catch(err => {
          console.log(err);
          setConfirmLoading(false);
        });
  };

  const reject0rAssignBack = () => {
    setConfirmLoading(true);
    userType === 'transcriptionist' &&
      rejectTranscriptorJob(jobId, user.sessionId)
        .then(response => {
          if (response.data.success) {
            setConfirmLoading(false);
            history.push(`/dashboard/jobs-list/rejected`);
            notification['success']({
              message: 'Success',
              description: response.data.message
            });
          } else {
            setConfirmLoading(false);
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
          }
        })
        .catch(err => {
          console.log(err);
          setConfirmLoading(false);
        });

    let reviewerComment = {
      comment: comments
    };

    userType === 'reviewer' &&
      rejectReviewerJob(jobId, reviewerComment, user.sessionId)
        .then(response => {
          if (response.data.success) {
            setConfirmLoading(false);
            history.push(`/dashboard/jobs-list/completed`);
            notification['success']({
              message: 'Success',
              description: response.data.message
            });
          } else {
            setConfirmLoading(false);
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
          }
        })
        .catch(err => {
          console.log(err);
          setConfirmLoading(false);
        });
  };

  return loading && (editorLoading || demographicsLoading) ? (
    <Spinner />
  ) : (
    <>
      <PageTitle title='Transcription Editor' />
      <ContainerRow>
        <TitleRow>
          <Col span={24}>
            <CustomTypography color={theme['@text-color']}>
              {userType === 'transcriptionist'
                ? 'Transcription Editor'
                : 'Transcription Review'}
            </CustomTypography>
          </Col>
          <Col span={24}>
            <BreadCrumbComponent items={BreadCrumbItems} />
          </Col>
        </TitleRow>
        <DividerStyle />
        <HeadingRow gutter={[12, 24]}>
          <Col span={18}>
            <CustomAudioPlayer
              background={theme['@primary-color']}
              listenInterval={2000}
              ref={audioRef}
              src={audioUrl}
              onListen={event => {
                audioUpdate(event.target.currentTime);
              }}
              onPause={event => {
                audioUpdate(event.target.currentTime);
              }}
            />
          </Col>
          <Col span={3}>
            <ActionButton
              type='primary'
              shape='round'
              onClick={confirmCompleteOrApprove}
            >
              {userType === 'transcriptionist' ? 'Complete' : 'Approve'}
            </ActionButton>
          </Col>
          <Col span={3}>
            <ActionButton
              shape='round'
              reject={true}
              onClick={confirmRejectorAssignBack}
            >
              {userType === 'transcriptionist' ? 'Reject' : 'Assign Back'}
            </ActionButton>
          </Col>
        </HeadingRow>
        <ContentRow gutter={[24, 24]}>
          <Col span={18}>
            <EditorComponent
              jobId={jobId}
              content={demographicsData}
              editorData={editorData}
              headings={headings}
              inferenceActive={inferenceActive}
              setInferenceActive={setInferenceActive}
              audioRef={audioRef}
              deleteSection={deleteSectionHandler}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              saveStatus={saveStatus}
              setSaveStatus={setSaveStatus}
              setChangeStatus={setChangeStatus}
              jobName={jobName}
              loading={editorLoading}
              fetchContents={fetchContents}
              inferenceAnnotation={inferenceAnnotation}
              setInferenceAnnotation={setInferenceAnnotation}
              lastSavedTime={lastSavedTime}
              saveTimeFormat={saveTimeFormat}
              setEditorDataPreviewValue={setEditorDataPreviewValue}
              setEditorData={setEditorData}
              setEditorLoading={setEditorLoading}
            />
          </Col>
          <Col span={6}>
            <Demographics
              theme={theme}
              editorData={editorDataPreviewValue}
              content={inferenceActive ? inferenceData : demographicsData}
              inference={inferenceActive}
              loading={demographicsLoading}
              fetchContents={fetchContents}
              updateInferenceData={updateInferenceData}
              updateInferenceFlag={updateInferenceFlag}
              inferenceAnnotation={inferenceAnnotation}
              setInferenceAnnotation={setInferenceAnnotation}
              activeSection={activeSection}
              emptySection={emptySection}
              updateInferenceLoading={updateInferenceLoading}
              setUpdateInferenceLoading={setUpdateInferenceLoading}
            />
          </Col>
        </ContentRow>
      </ContainerRow>
      <ConfirmationModal
        visible={
          confirmationModal || completeTranscription || rejectTranscription
        }
        onOk={
          confirmationModal
            ? deleteConfirmationHandler
            : completeTranscription
            ? completeOrApprove
            : reject0rAssignBack
        }
        onCancel={deleteCancelHandler}
        antdModalProps={{ centered: true, width: '300', closable: false }}
        loading={confirmLoading}
        confirmation={true}
        buttonDisabled={
          userType === 'reviewer' &&
          !confirmationModal &&
          !completeTranscription &&
          comments.length <= 0
        }
      >
        {confirmationModal ? (
          <DeleteIcon />
        ) : completeTranscription ? (
          <ConfirmationIcon width={'66'} height={'66'} />
        ) : (
          <RejectIcon />
        )}

        {confirmationModal && (
          <p>Are you sure you would want to delete this section?</p>
        )}
        {completeTranscription && (
          <p>
            Are you sure you want to{' '}
            {userType === 'transcriptionist' ? 'save' : 'approve'} this
            transcription?
          </p>
        )}
        {rejectTranscription && (
          <p>
            Are you sure you want to{' '}
            {userType === 'transcriptionist' ? 'reject' : 'assign back'} this
            transcription?
          </p>
        )}
        {rejectTranscription && userType === 'reviewer' && (
          <StyledTextArea
            placeholder='Enter Your Comments'
            rows={4}
            onChange={event => {
              setComments(event.target.value);
            }}
          />
        )}
      </ConfirmationModal>
      <SuccessModal
        visible={successModal}
        onOk={() => {
          setSuccessModal(false);
        }}
        antdModalProps={{ centered: true, width: '300', closable: false }}
        message='Section removed!'
      />
    </>
  );
};

export default Editor;
