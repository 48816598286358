import SockJs from 'sockjs-client';
import Stomp from 'stompjs';
import { getBaseUrl } from '../Config/AxiosInstance';
import {
  newJobAvailable,
  readyJobsModified,
  transcriptionWriteFailed,
  newNotification,
  jobReadyToInprogress
} from '../Redux/Actions/Notification';
import store from '../Redux';
// import AlertSound from '../Assets/Alert/alert.mp3';

let stompClient = null;

export const connectSocket = () => {
  let baseUrl = getBaseUrl();
  let url = `${baseUrl}/public/websocket`;
  let socket = new SockJs(url);
  stompClient = Stomp.over(socket);
  // let audio = new Audio(AlertSound);
  var user = JSON.parse(localStorage.getItem('user'));
  stompClient.connect({}, () => {
    stompClient.subscribe(`/user/${user && user.id}/message`, message => {
      var result = JSON.parse(message.body);
      if (result.target === 'REVIEWER_REJECTED')
        store.dispatch(readyJobsModified(result));
      else if (result.target === 'NEW_JOB_AVAILABLE')
        store.dispatch(newJobAvailable(result));
      else if (result.target === 'TRANSCRIPTION_WRITE_FAILED')
        store.dispatch(transcriptionWriteFailed(result));
      else if (result.target === 'JOB_READY_INPROGRESS')
        store.dispatch(jobReadyToInprogress(result));
      else store.dispatch(newNotification(result));
      // audio.play();
    });
  });
};

export const disconnectSocket = () => {
  stompClient.disconnect(() => {
    console.log('Disconnected');
  });
};
