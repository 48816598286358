import axiosInstance from '../Config/AxiosInstance';

const PREFIX = `/clinic/user`;

export const UserApi = userId => {
  return axiosInstance.get(PREFIX + `/profile?userId=${userId}`);
};

export const UserUpdateApi = data => {
  return axiosInstance.put(PREFIX + '/basic-details', data);
};
export const UserTranscriptionFlowApi = data => {
  return axiosInstance.put(PREFIX + '/dictation-flow-config', data);
};

export const UserProfileUpdateApi = (data, userId) => {
  return axiosInstance.post(
    PREFIX + `/upload-profile-pic?userId=${userId}`,
    data
  );
};

export const FetchProfilePicture = userId => {
  return axiosInstance.get(PREFIX + `/download-profile-pic?userId=${userId}`);
};

export const DeleteProfilePicture = userId => {
  return axiosInstance.delete(PREFIX + `/delete-profile-pic?userId=${userId}`);
};

export const EmailPasswordReset = data => {
  return axiosInstance.put(PREFIX + `/credentials`, data);
};

export const FetchNotifications = (userId, page, size, notificationType) => {
  return axiosInstance.get(
    PREFIX +
      `/${userId}/notifications?page=${page}&size=${size}&notificationType=${notificationType}`
  );
};

export const ReadNotification = (userId, notificationId, readAll) => {
  return axiosInstance.put(
    PREFIX +
      `/${userId}/notifications?notificationId=${notificationId}&readAll=${readAll}`
  );
};

export const reInviteUser = userId => {
  return axiosInstance.post(PREFIX + `/${userId}/re-invite-user`);
};

export const getUserNotificationDashboard = userId => {
  return axiosInstance.get(PREFIX + `/${userId}/notification-dashboard`);
};

export const getUserFacilities = (userId, userType, searchVal = '') => {
  console.log('logb', searchVal, userType, userId);

  if (userType === 'biller') {
    return axiosInstance.get(
      PREFIX + `/facilities?searchValue=${searchVal}&userId=${userId}`
    );
  } else {
    return axiosInstance.get(PREFIX + `/facilities?searchValue=${searchVal}`);
  }
};

export const getUserOnlyAssocautedWithFacilitie = facilityId => {
  return axiosInstance.get(PREFIX + `/${facilityId}`);
};

export const getJobFilterConfig = filter_page => {
  return axiosInstance.get(PREFIX + `/${filter_page}/filter`);
};

export const saveConfiguration = (filter_page, data) => {
  return axiosInstance.post(PREFIX + `/${filter_page}/filter`, data);
};

export const fetchFacilityAuthor = () => {
  return axiosInstance.get(PREFIX + '/filter');
};
export const getReviewers = () => {
  return axiosInstance.get(PREFIX + '/reviewers');
};
