import React, { useEffect, useRef, useState } from 'react';
// import { FloatingMenu } from './Helper/FloatingMenu';

import {
  EditorContainerRow,
  EditorTitleRow,
  EditorNameDiv,
  CustomNameStyle,
  CustomConsultationTypeStyle,
  EditorToolsRow,
  CustomJobHeadingText,
  CustomEditorButtonDivStyle,
  CustomEditorButtonStyle,
  CustomJobNameIdStyle,
  StyledToolsContainer,
  RerenderButtonContainer,
  DeleteConfirmationMessage,
  UndoRedoButtonContainer,
  CustomUndoRedoButtonStyle,
  CustomModalStyle
} from './styledComponents';
import { StyledTag } from '../../Content/styledComponents';
import {
  SyncOutlined,
  CloudUploadOutlined,
  WarningOutlined
} from '@ant-design/icons';
import BrainEditorIcon from '../../../Assets/Icons/BrainEditorIcon';
import HeadingIcon from '../../../Assets/Icons/HeadingIcon';
import { Modal, notification, Tooltip, message, Spin, Select } from 'antd';
import SaveEditorIcon from '../../../Assets/Icons/SaveEditorIcon';
import { reconvertJob, GetWorkType, updateWorkType } from '../../../Apis/Jobs';
import QuillEditor from '../../CustomComponents/QuillEditor';
import { useSelector } from 'react-redux';
import RerenderIcon from '../../../Assets/Icons/RerenderIcon';
import ConfirmationModal from '../ConfirmationModal';
import Spinner from '../Spinner';
import WarningIcon from '../../../Assets/Icons/WarningIcon';
import UndoIcon from '../../../Assets/Icons/UndoIcon';
import RedoIcon from '../../../Assets/Icons/RedoIcon';

// import { Select } from 'antd';
// import Item from 'antd/lib/list/Item';
// const ref = useRef (null);

const NewEditorComponent = props => {
  const editorRef = useRef(null);
  const [selectedWorkType, setSelectedWorkType] = useState();
  const [refreshKey, setRefreshKey] = useState(0);
  const [isHeading, setIsHeading] = useState(false);
  const [theme] = useSelector(state => [state.themeReducer]);
  const [revertJobModalVisible, setRevertJobModalVisible] = useState(false);

  const [workTypeOptions, setWorkTypeOptions] = useState([]);
  const [workTypeLoader, setWorkTypeLoader] = useState(false);
  const [workType, setWorkType] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setWorkTypeLoader(true);
    updateWorkType(props.jobId, user.sessionId, selectedWorkType).then(() => {
      notification.success({ message: 'Worktype Updated' });
      props.setRefetchDemographics(true);
      props.setInferenceActive(false);
      setWorkTypeLoader(false);
    });
    setSelectedWorkType();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedWorkType();
  };
  const user = JSON.parse(localStorage.getItem('user'));
  function processValue(value) {
    // Do something with the value, for example, log it

    return value.replace(/<\/p>/g, '</p>\n').replace(/<\/?p>/g, '');

    // You can also perform other operations here
  }

  const revertJob = () => {
    props.setEditorLoading(true);
    reconvertJob(props.jobId)
      .then(response => {
        // const inputString = '';
        // response.data.result[0].sections[0].content[0].value;
        // console.log('str', inputString);
        if (response.data.success) {
          for (let i = 0; i < response.data.result.length; i++) {
            const resultItem = response.data.result[i];
            for (let j = 0; j < resultItem.sections.length; j++) {
              const section = resultItem.sections[j];
              for (let k = 0; k < section.content.length; k++) {
                const contentItem = section.content[k];
                if (contentItem.type === 'Text') {
                  response.data.result[i].sections[j].content[k].value =
                    processValue(contentItem.value);
                }
              }
            }
          }

          // response.data.success[0].result.sections.content.value;
          // const inputString =
          //   response.data.result[0];
          // const formattedString = inputString..sections[0].content[0].value
          //   .replace(/<\/?p>/g, '\n')
          //   .replace(/<p>/g, '');

          // console.log('Hiii', formattedString);
          const abc =
            '<p><strong>oh my god</strong></p> pressure 105. <p><strong>oh my god</strong></p> sugar were not 5'

              .replaceAll('<br>', '')
              .replaceAll('&nbsp;', '')
              .replace(/<\/?p>/g, '')
              .replace(/<p>/g, '');

          props.setSections(
            response.data.result[0] && response.data.result[0].sections
          );

          props.setActiveSection(
            response.data.result[0].sections &&
              response.data.result[0].sections.length > 0 &&
              response.data.result[0].sections[0].id
          );

          props.setLastUpdateTime(response.data.result[0].lastUpdatedTime);
          props.setEditorLoading(false);

          setRevertJobModalVisible(false);
        } else {
          notification['error']({
            message: 'Section Fetch Failed',
            description: response.data.message
          });
          props.setEditorLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        props.setEditorLoading(false);
      });
  };

  useEffect(() => {
    if (props.saveStatus === 'Saved') {
      setTimeout(() => {
        props.setSaveStatus(null);
      }, 1000);
    }
    // eslint-disable-next-line
  }, [props.saveStatus, props.loading]);
  const getOptionObjectsFromArray = options => {
    let newObj = [];
    return options.map(item =>
      Object.assign(...newObj, { value: item, label: item })
    );
  };
  useEffect(() => {
    GetWorkType(props.jobId).then(res => {
      const options = getOptionObjectsFromArray(res.data.workTypes);
      if (props.demographicsData) {
        let work = props.demographicsData?.filter(
          data => data?.key === 'WORK TYPE'
        )[0]?.value;
        let t = options.filter(data => data.value === work)?.[0];
        setWorkType(t);
      }
      setWorkTypeOptions(options);
    });
  }, [props.jobId, props.demographicsData]);
  const handleChange = value => {
    setSelectedWorkType(value);
    setWorkType(value);
    updateWorkType(props.jobId, user.sessionId, value).then(() => {
      props.setRefetchDemographics(true);
      props.setInferenceActive(false);
    });
    setSelectedWorkType(value);

    showModal();
  };
  const handleSearch = searchValue => {
    // Perform search-related actions if needed
  };

  return (
    <>
      <CustomJobHeadingText color={'#47A4EA'}></CustomJobHeadingText>
      <EditorContainerRow>
        <EditorTitleRow>
          <CustomJobNameIdStyle>
            <EditorNameDiv prefix={'true'}>
              <CustomNameStyle color={theme['@table-text-color']}>
                {
                  props.demographicsData?.filter(
                    data => data?.key === 'NAME'
                  )[0]?.value
                }
              </CustomNameStyle>

              <CustomConsultationTypeStyle>
                {/* {props.demographicsData?.filter((data) => data?.key === 'WORK TYPE')[0]?.value} */}
                {workTypeLoader ? (
                  <Spin />
                ) : (
                  <Select
                    key={refreshKey}
                    value={workType}
                    style={{
                      width: '175px'
                    }}
                    bordered={false}
                    showSearch={true}
                    onSearch={handleSearch}
                    onChange={handleChange}
                    options={workTypeOptions}
                    className='custom-dropdown'
                  />
                )}
              </CustomConsultationTypeStyle>
              <CustomModalStyle
                title='Updated work type'
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <p>Update WorkType To {selectedWorkType && selectedWorkType}</p>
              </CustomModalStyle>
            </EditorNameDiv>
            <CustomJobHeadingText color={theme['@primary-color']}>
              Job ID: {props.jobName}
            </CustomJobHeadingText>
          </CustomJobNameIdStyle>

          {/* <EditorButtonStyle>
            <StepButtonStyle>
              <EditorIconStyle type='text' icon={<StepBackwardOutlined />} />
              <EditorIconStyle type='text' icon={<StepForwardOutlined />} />
            </StepButtonStyle>
            <EditorIconStyle type='text' icon={<DownOutlined />} />
          </EditorButtonStyle> */}
          <EditorNameDiv suffix={true}>
            <CustomNameStyle>Last Updated</CustomNameStyle>
            <CustomConsultationTypeStyle>
              {/* {new Date(lastUpdateTime).toLocaleString()} */}
              {props.lastUpdateTime && props.formatTime(props.lastUpdateTime)}
            </CustomConsultationTypeStyle>
          </EditorNameDiv>
        </EditorTitleRow>
        <EditorToolsRow>
          {/* <CustomHeadingText color={theme['@table-text-color']}>
            Audio Name 12365478987452336
          </CustomHeadingText> */}
          <CustomEditorButtonDivStyle>
            <StyledToolsContainer>
              <Tooltip placement='bottom' title='Inference Engine'>
                <CustomEditorButtonStyle
                  icon={
                    <BrainEditorIcon
                      fill={
                        props.inferenceActive &&
                        !props.newInferenceActive &&
                        theme['@primary-color']
                      }
                    />
                  }
                  onClick={() => {
                    props.setInferenceActive(
                      !(props.inferenceActive && !props.newInferenceActive)
                    );
                    props.setNewInferenceActive(
                      props.inferenceActive ? false : props.newInferenceActive
                    );
                  }}
                />
              </Tooltip>
              {/* <Tooltip placement='bottom' title='Inference Engine(New)'>
                <CustomEditorButtonStyle
                  icon={
                    <BrainEditorIcon
                      fill={
                        props.inferenceActive && props.newInferenceActive
                          ? '#f57542'
                          : '#fabca2'
                      }
                    />
                  }
                  onClick={() => {
                    props.setInferenceActive(!props.newInferenceActive);
                    props.setNewInferenceActive(!props.newInferenceActive);
                  }}
                />
              </Tooltip> */}
              <Tooltip placement='bottom' title='Save'>
                <CustomEditorButtonStyle
                  icon={
                    <SaveEditorIcon
                      fill={props.viewOnly ? '' : theme['@primary-color']}
                    />
                  }
                  onClick={() => {
                    editorRef.current.saveData();
                  }}
                  hidden={props?.viewOnly}
                />
              </Tooltip>
              <Tooltip placement='bottom' title='Heading Tool'>
                <CustomEditorButtonStyle
                  icon={
                    <HeadingIcon
                      fill={
                        isHeading || props.viewOnly
                          ? ''
                          : theme['@primary-color']
                      }
                    />
                  }
                  onClick={event => {
                    event.preventDefault();
                    editorRef.current.headingHandler();
                  }}
                  hidden={props?.viewOnly}
                />
              </Tooltip>
              {props.saveStatus && (
                <StyledTag
                  icon={
                    props.saveStatus === 'Saved' ? (
                      <CloudUploadOutlined />
                    ) : props.saveStatus === 'Saving' ? (
                      <SyncOutlined spin />
                    ) : props.saveStatus === 'You are Offline' ? (
                      <WarningOutlined />
                    ) : null
                  }
                >
                  {props.saveStatus}
                </StyledTag>
              )}
            </StyledToolsContainer>
            <UndoRedoButtonContainer>
              <Tooltip placement='bottom' title='Undo'>
                <CustomUndoRedoButtonStyle
                  icon={<UndoIcon />}
                  onClick={() => {
                    editorRef.current.handleUndo();
                  }}
                  hidden={props?.viewOnly}
                />
              </Tooltip>
              <Tooltip placement='bottom' title='Redo'>
                <CustomUndoRedoButtonStyle
                  icon={<RedoIcon />}
                  onClick={() => {
                    editorRef.current.handleRedo();
                  }}
                  hidden={props?.viewOnly}
                />
              </Tooltip>
            </UndoRedoButtonContainer>
            <RerenderButtonContainer>
              <Tooltip placement='bottom' title='Reconvert'>
                <CustomEditorButtonStyle
                  icon={<RerenderIcon />}
                  onClick={() => {
                    setRevertJobModalVisible(true);
                  }}
                  hidden={props?.viewOnly}
                />
              </Tooltip>
            </RerenderButtonContainer>
          </CustomEditorButtonDivStyle>
        </EditorToolsRow>
        {props.loading ? (
          <Spinner height='calc(100vh - 400px)' />
        ) : (
          <QuillEditor
            sections={props.sections}
            headings={props.headings}
            jobName={props.jobName}
            jobId={props.jobId}
            lastUpdateTime={props.lastUpdateTime}
            setLastUpdateTime={props.setLastUpdateTime}
            ref={editorRef}
            saveStatus={props.saveStatus}
            setSaveStatus={props.setSaveStatus}
            isHeading={isHeading}
            setIsHeading={setIsHeading}
            theme={theme}
            audioRef={props.audioRef}
            activeSection={props.activeSection}
            setActiveSection={props.setActiveSection}
            fetchContents={props.fetchContents}
            loading={props.loading}
            setEditorLoading={props.setEditorLoading}
            deleteSectionHandler={props.deleteSectionHandler}
            changeStatus={props.changeStatus}
            activeSectionContent={props.activeSectionContent}
            setActiveSectionContent={props.setActiveSectionContent}
            viewOnly={props?.viewOnly}
            localSectionIdMap={props.localSectionIdMap}
          />
        )}
      </EditorContainerRow>

      <ConfirmationModal
        visible={revertJobModalVisible}
        onOk={() => revertJob()}
        onCancel={() => {
          setRevertJobModalVisible(false);
        }}
        loading={props.loading}
        confirmation={true}
        antdModalProps={{ closable: false }}
      >
        <WarningIcon />
        <DeleteConfirmationMessage>
          Are you sure you want to reconvert the dictation?
        </DeleteConfirmationMessage>
      </ConfirmationModal>
    </>
  );
};

export default NewEditorComponent;
