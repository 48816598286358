import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  StyledSearch,
  StyledContainer,
  StyledAccordian,
  StyledPanel,
  StyledRow,
  StyledCheckBox,
  StyledCircle,
  StyledContent,
  StyledMessage
} from './styledComponents';
import { SearchOutlined } from '@ant-design/icons';
import { Span } from 'slate';

const status = [
  { key: 'All', value: 'All' },
  { key: 'New', value: 'Ready' },
  { key: 'Rejected', value: 'Rejected' }
];
const FilterConfiguration = ({
  Authors,
  activeTab,
  facilities,
  Modified,
  setModified,
  jobStatus
}) => {
  const getAlphabets = string => {
    return string
      .split(' ')
      .map(function (s) {
        return s.charAt(0);
      })
      .join('')
      .substring(0, 2)
      .toUpperCase();
  };
  const user = useSelector(state => state.userReducer);
  const [facilitySearchValue, setfacilitySearchValue] = useState('');
  const [authorSearchValue, setauthorSearchValue] = useState('');
  const [facilityFilterValues, setfacilityFilterValues] = useState(facilities);
  const [authorFilterValues, setauthorFilterValues] = useState(Authors);

  const getSearchResult = (searchKey, SearchList, filterKey) => {
    let result = [];
    if (searchKey) {
      result = SearchList.filter(item =>
        item[filterKey].toLowerCase().includes(searchKey.toLowerCase())
      );
    } else {
      result = SearchList;
    }
    return result;
  };
  const filterSearch = (filter, value) => {
    if (filter === 'facility') {
      setfacilitySearchValue(value);
      let filterFacility = getSearchResult(value, facilities, 'facilityName');
      setfacilityFilterValues(filterFacility);
    } else {
      setauthorSearchValue(value);
      let filterAuthors = getSearchResult(value, Authors, 'doctorName');
      setauthorFilterValues(filterAuthors);
    }
  };

  const onSelect = (field, value) => {
    let selArray = [];
    let obj = {};
    if (field === 'status') {
      if (Boolean(Modified[field].indexOf(value) >= 0)) {
        selArray = [];
      } else {
        selArray.push(value);
      }
    } else {
      selArray = Modified[field] ? Modified[field] : [];
      if (Boolean(selArray.indexOf(value) >= 0)) {
        const index = selArray.indexOf(value);
        selArray.splice(index, 1);
      } else {
        selArray.push(value);
      }
    }
    obj[field] = selArray;
    setModified({ ...Modified, ...obj });
  };

  return (
    <StyledContainer>
      <StyledAccordian expandIconPosition='end' defaultActiveKey={['1']}>
        {activeTab === 'reviewer' ? (
          <StyledPanel header='Author' key='1'>
            <StyledSearch
              prefix={<SearchOutlined />}
              placeholder='Search'
              value={authorSearchValue}
              onChange={event => filterSearch('author', event.target.value)}
            ></StyledSearch>
            {authorFilterValues.some(
              person => person.doctorId === user?.id
            ) && (
              <StyledRow key={user?.id}>
                <StyledContent>
                  <StyledCircle>{getAlphabets('You')}</StyledCircle>
                  {'You'}
                </StyledContent>
                <StyledCheckBox
                  checked={Modified?.doctorIds?.indexOf(user?.id) >= 0}
                  onChange={event => onSelect('doctorIds', user?.id)}
                />
              </StyledRow>
            )}

            {authorFilterValues &&
              authorFilterValues.map((item, index) => {
                if (item?.doctorId !== user?.id) {
                  return (
                    <StyledRow key={item?.doctorName + item?.doctorId}>
                      <StyledContent>
                        <StyledCircle>
                          {getAlphabets(
                            item?.doctorId !== user?.id
                              ? item?.doctorName
                              : 'You'
                          )}
                        </StyledCircle>
                        {item?.doctorId !== user?.id ? item?.doctorName : 'You'}
                      </StyledContent>
                      <StyledCheckBox
                        checked={
                          Modified?.doctorIds?.indexOf(item?.doctorId) >= 0
                        }
                        onChange={event =>
                          onSelect('doctorIds', item?.doctorId)
                        }
                      />
                    </StyledRow>
                  );
                } else {
                  return null;
                }
              })}
            {authorFilterValues.length === 0 && (
              <StyledMessage>No Authors Found</StyledMessage>
            )}
          </StyledPanel>
        ) : null}
        <StyledPanel header='Facility' key='2'>
          <StyledSearch
            prefix={<SearchOutlined />}
            placeholder='Search'
            value={facilitySearchValue}
            onChange={event => filterSearch('facility', event.target.value)}
          ></StyledSearch>
          {facilityFilterValues &&
            facilityFilterValues.map((item, index) => {
              return (
                <StyledRow key={item?.facilityName}>
                  <StyledContent>
                    <StyledCircle>
                      {getAlphabets(item?.facilityName)}
                    </StyledCircle>
                    {item?.facilityName}
                  </StyledContent>
                  <StyledCheckBox
                    checked={
                      Modified?.facilityIds?.indexOf(item?.facilityId) >= 0
                    }
                    onChange={event =>
                      onSelect('facilityIds', item?.facilityId)
                    }
                  />
                </StyledRow>
              );
            })}
          {facilityFilterValues.length === 0 && (
            <StyledMessage>No Facilities Found</StyledMessage>
          )}
        </StyledPanel>
        {/* <StyledPanel disabled={jobStatus !== 'ready'} header='Status' key='3'>
          {status.map((item, index) => {
            return (
              <StyledRow key={item.key}>
                {item.key}
                <StyledCheckBox
                  value={Modified?.status?.indexOf(item?.value) >= 0}
                  disabled={jobStatus !== 'ready'}
                  checked={item.value === Modified['status'][0]}
                  onChange={event => onSelect('status', item.value)}
                />
              </StyledRow>
            );
          })}
        </StyledPanel> */}
      </StyledAccordian>
    </StyledContainer>
  );
};
export default FilterConfiguration;
