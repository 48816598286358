import React, { useState, useEffect, useCallback } from 'react';
import {
  TitleRow,
  ContainerRow,
  SearchInputStyle,
  ControlsDiV
} from '../styledComponents';
import CustomTypography from '../../CustomComponents/CustomTypography';
import { useSelector } from 'react-redux';
import PageTitle from '../../../Utils/PageTitle';
import CustomTable from '../../CustomComponents/Table';
import {
  RESULTS_PER_PAGE,
  SEARCH_DELAY
} from '../../../Constants/StringConstants';
import { columns } from './tableConfig';
import AddAdminForm from './AddAdminForm';
import CustomSideDrawer from '../../CustomComponents/CustomSideDrawer';
import { listAll, saveAdmin, editAdmin } from '../../../Apis/Admin';
import { notification } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { useDebounce } from '../../../Utils/Debounce';
import { AddButton } from '../styledComponents';
import { reInviteUser } from '../../../Apis/User';
import FacilityFilter from '../../CustomComponents/FacilityFilter';
import { getUserFacilities, getReviewers } from '../../../Apis/User';
import { disableOrEnableUser } from '../../../Apis/ClinicAdmin';

const Admins = () => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const [searchString, setSearchString] = useState('');
  const [total, setTotal] = useState(0);
  const [admins, setAdmins] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [addDrawerVisible, setAddDrawerVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [filterKey, setFilterKey] = useState('name');
  const [sortOrder, setSortOrder] = useState('');
  const [facilityIds, setFacilityIds] = useState([]);
  const [facilities, setFacilities] = useState();
  const userData = useSelector(state => state.userReducer);
  const notClinicAdmin = userData.userType !== 'clinicAdmin';
  const [reviewersList, setReviewersList] = useState([]);
  const submitHandler = values => {
    values.reviewerList = values.reviewerList.includes('none')
      ? []
      : values.reviewerList;
    setSubmitLoading(true);
    const payload = values;
    values.phoneNumber.number = values.phoneNumber.number.replace(
      values.phoneNumber.countryCode,
      ''
    );
    if (selectedItem) {
      payload.id = selectedItem.id;
      editAdmin(payload)
        .then(response => {
          if (response.data.success) {
            notification['success']({
              message: 'Updated',
              description: 'Admin details updated.'
            });
            setSubmitLoading(false);
            setAddDrawerVisible(false);
            setSelectedItem(null);
            fetchAll(currentPage);
          } else {
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
            setSubmitLoading(false);
          }
        })
        .catch(err => {
          setSubmitLoading(false);
          console.log(err);
        });
    } else {
      saveAdmin(payload)
        .then(response => {
          if (response.data.success) {
            notification['success']({
              message: 'Added',
              description: 'Admin added successfully'
            });
            setSubmitLoading(false);
            setAddDrawerVisible(false);
            setSelectedItem(null);
            fetchAll(0);
            setCurrentPage(0);
          } else {
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
            setSubmitLoading(false);
          }
        })
        .catch(err => {
          setSubmitLoading(false);
          console.log(err);
        });
    }
  };

  const getFacilities = () => {
    getUserFacilities()
      .then(response => {
        if (response.data.success) {
          setFacilities(
            response.data.result.map(item => {
              return {
                label: item.facilityName,
                value: item.facilityId,
                disabled: item.disabled,
                className: item.disabled ? 'disabled-facility-option' : ''
              };
            })
          );
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => console.log(err));
  };

  const disableUser = id => {
    disableOrEnableUser(id)
      .then(response => {
        if (response.data.success) {
          notification['success']({
            message: 'Success',
            description: response.data.message
          });
          fetchAll(0);
          setCurrentPage(0);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const reInvite = id => {
    reInviteUser(id)
      .then(response => {
        if (response.data.success) {
          notification['success']({
            message: 'Success',
            description: response.data.message
          });
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetchAll = pageNo => {
    setLoading(true);
    listAll(
      facilityIds,
      searchString,
      RESULTS_PER_PAGE,
      pageNo,
      filterKey,
      sortOrder
    )
      .then(response => {
        setLoading(false);
        // setFetchAgain(false);
        if (response.data.success) {
          setTotal(response.data.size);
          setAdmins(response.data.result);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => {
        setLoading(false);
        // setFetchAgain(false);
      });
    getReviewers()
      .then(response => {
        if (response.data.success) {
          // setTotal(response.data.size);
          setReviewersList(response.data.result);
          console.log('response.data.result', response.data.result);
        }
      })
      .catch(() => {
        setLoading(false);
        // setFetchAgain(false);
      });
  };

  const filterTableChange = (pagination, filters, sorter) => {
    if (sorter.field !== undefined)
      setFilterKey(sorter.field && sorter.field[0]);
    if (sorter.order !== undefined) setSortOrder(Boolean(sorter.order));
  };

  const onChangeHandler = value => {
    setCurrentPage(0);
    setSearchString(value);
  };

  const debounceOnChange = useCallback(
    useDebounce(onChangeHandler, SEARCH_DELAY),
    []
  );

  const pageNoChangeHandler = pageNo => {
    fetchAll(pageNo);
  };

  useEffect(() => {
    fetchAll(0);
    // eslint-disable-next-line
  }, [filterKey, searchString, sortOrder, facilityIds]);

  useEffect(() => {
    getFacilities();
  }, []);

  return (
    <>
      <PageTitle title='Doctors' />
      <ContainerRow gutter={[0, 40]}>
        <TitleRow justify='space-between'>
          <CustomTypography color={theme['@text-color']}>
            Admins
          </CustomTypography>
          <ControlsDiV>
            <AddButton
              icon={<PlusOutlined />}
              type='primary'
              shape='round'
              onClick={() => setAddDrawerVisible(true)}
            >
              Add
            </AddButton>
            <SearchInputStyle
              placeholder='Search'
              prefix={<SearchOutlined />}
              onChange={event => debounceOnChange(event.target.value)}
            />
            <FacilityFilter setSelectedValues={setFacilityIds} />
          </ControlsDiV>
        </TitleRow>
        <CustomTable
          theme={theme}
          columns={columns(
            setSelectedItem,
            setAddDrawerVisible,
            disableUser,
            reInvite,
            notClinicAdmin
          )}
          dataSource={admins}
          pageNoChangeHandler={pageNoChangeHandler}
          loading={loading}
          setPageNo={current => {
            setCurrentPage(current);
          }}
          pageNo={currentPage}
          pageSize={RESULTS_PER_PAGE}
          total={total}
          onChange={filterTableChange}
          user={true}
        />

        <CustomSideDrawer
          title={selectedItem ? 'Edit Admin' : 'Add Admin'}
          placement='right'
          closable={false}
          onClose={() => {
            setSelectedItem(null);
            setAddDrawerVisible(false);
          }}
          visible={addDrawerVisible}
          width={400}
          destroyOnClose={true}
        >
          <AddAdminForm
            facilities={facilities}
            selectedItem={selectedItem}
            submitHandler={submitHandler}
            reviewerList={reviewersList}
            submitLoading={submitLoading}
          />
        </CustomSideDrawer>
      </ContainerRow>
    </>
  );
};

export default Admins;
